import * as React from 'react'
import { CircularProgress, Grid, Step, StepConnector, withStyles } from '@material-ui/core'
import { BrowserLocaleDateFormatter, BrowserLocaleTimeFormatter, activeTimeFrame, FlowCall, FlowCutOff, NewMultiSelect, SvgIcon, Title, useInfiniteScroll, Tooltip, useDebounce } from 'prace-common-components'
import { getCurrentCutoff } from 'util/getCurrentCutoff'
import { $Arrow, $CallContainer, $Calls, $CallSelect, $CallUID, $Content, $CutoffButton, $CutoffContainer, $Date, $Number, $Status, $StepContainer, $StepIcon, $StepLabel, $Stepper, $SvgIcon } from './styles'
import { useGetCallIdsQuery, useLazyGetCallsDashboardQuery } from 'store/api/calls'
import dateUTC from 'util/dateUTC'
import { DashboardCutoff } from './types'
import { useNavigate } from 'react-router-dom'
import { DASHBOARD_STATS, Routes } from 'constants/global'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 13,
  },
  active: {
    backgroundColor: 'black',
    '& $line': {
      backgroundColor: 'black',
    },
  },
  completed: {
    backgroundColor: '#eaeaf0',
    '& $line': {
      backgroundColor: '#eaeaf0',
    },
  },
  line: {
    minWidth: 3,
    marginLeft: -13,
    marginRight: -13,
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: '#eaeaf0',
  },
})(StepConnector)


const stepIcon = (open: boolean, completed: boolean, pendingActions: boolean, icon: string) => {
  return (
    <$StepIcon className={open ? 'active' : completed ? 'completed' : ''} active={open} completed={completed}>
      <SvgIcon size='16' color='#fff' name={!pendingActions ? 'check' : icon} />
    </$StepIcon>
  )
}

export const Dashboard: React.FC<{isMobile?: boolean}> = () => {
  const navigate = useNavigate()
  const { data: callsData } = useGetCallIdsQuery()
  const [offset, setOffset] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [calls, setCalls] = React.useState<FlowCall[]>([])
  const [selectedCalls, setSelectedCalls] = React.useState<StringNumber[]>(JSON.parse(window.localStorage.getItem('dashboardIds') || '[]') || [])
  const debouncedCalls = useDebounce(selectedCalls, 1000) as StringNumber[]
  const [cutoffSelection, setCutoffSelection] = React.useState<{ [key: number]: number }>({})
  const [stepSelection, setStepSelection] = React.useState<{ [key: number]: number }>({})
  const [getDashboardCall] = useLazyGetCallsDashboardQuery()

  const fetchDashboardCall = React.useCallback(async (offset: number, callIds?: number[]) => {
    try {
      setLoading(true)
      const { data: fetchedCall } = await getDashboardCall({ limit: 1, offset, callIds })
      if (fetchedCall?.data) {
        const call = fetchedCall.data[0]
        const currentCutoff = getCurrentCutoff(call.cutoffs) as FlowCutOff & { steps: any[] } /* TODO: handle typing */
        const activeStepIdx = currentCutoff.steps.findIndex(
          (step) => !step.submission.length || !step.acceptance.length || !step.approval.length)
        setStepSelection((stepSelection) => 
          offset ? ({ ...stepSelection, [call.id]: activeStepIdx || 0 }) : { [call.id]: activeStepIdx || 0 })
        setCutoffSelection((selection) => 
          offset ? ({ ...selection, [call.id]: currentCutoff?.id || 0 }) : { [call.id]: currentCutoff?.id || 0 })
        setCalls((prev) => offset ? [...prev, call] : [call])
        setOffset(offset)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [getDashboardCall])
  
  React.useEffect(() => {
    fetchDashboardCall(0, debouncedCalls as number[])
  }, [fetchDashboardCall, debouncedCalls])

  const onCutoffSelect = (callId: number, cutoffId: number) => {
    setCutoffSelection((prev) => ({ ...prev, [callId]: cutoffId }))
  }

  const onSelectCalls = (calls: StringNumber[]) => {
    setSelectedCalls(calls)
    window.localStorage.setItem('dashboardIds', JSON.stringify(calls))
  }

  const navigateWithState = (state: any) => {
    if(!state.ids?.length) return
    window.localStorage.setItem('dashboardState', JSON.stringify(state))
    navigate(Routes.APPLICATIONS)
  }

  const callItems = callsData?.data?.filter((c) => c.published)
    .map((call) => ({ value: call.id, label: call.uid })) || []

  const hasMore = (offset + 1) < (debouncedCalls?.length ? debouncedCalls.length : callItems.length)
  const { loadMoreRef } = useInfiniteScroll(
    () => fetchDashboardCall(offset + 1, debouncedCalls as number[]), hasMore && !loading)

  return (
    <>
      <$Calls>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Title bold alternate fontSize={20}>Ongoing Calls</Title>
          </Grid>
          <Grid item xs={6} md={4}>
            <$CallSelect>
              <NewMultiSelect
                title='Selected Calls'
                hideItalic
                name='calls'
                items={callItems}
                onChange={(_n, value) => onSelectCalls(value)}
                value={selectedCalls as string[]}
              />
            </$CallSelect>
          </Grid>
        </Grid>
        <div>
          {calls.map((call) => {
            const selectedCutoffId = cutoffSelection[call.id] || undefined
            const selectedCutoff = call.cutoffs.find((cutoff) => cutoff.id == selectedCutoffId) as DashboardCutoff
            const selectedStepIdx = stepSelection[call.id] || 0
            const selectedStep = call.flow[selectedStepIdx]
            const hasApproval = selectedStep.config?.hasAdminCheck
            const selectedStepTimeframe = selectedStep.timeframes
              .find((timeframe) => timeframe.cutoffId == selectedCutoffId)
            const stepActive = selectedStepTimeframe ? 
              activeTimeFrame({ ...selectedStep, timeframes: [selectedStepTimeframe]}) : false
            const selectedStepInfo = selectedCutoff?.steps[selectedStepIdx]

            const totalApprovals = selectedStepInfo?.submitted.length || 0
            const totalDrafts = (selectedStepInfo?.submission.length || 0) + 
              (selectedStepInfo?.expiredSubmission.length || 0) + 
              (selectedStepInfo?.submitted.length || 0)
            const totalAccepts = (selectedStepInfo?.acceptance.length || 0) + (selectedStepInfo?.accepted.length || 0)
            const totalChangesRequested = (selectedStepInfo?.changesRequest.length || 0) + 
            (selectedStepInfo?.changesRequested.length || 0)
            return (
              <$CallContainer key={call.id}>
                <div>
                  <Grid container alignItems='baseline'>
                    <div>{call.title}</div>
                    <$CallUID>{call.uid}</$CallUID>
                  </Grid>
                </div>
                <$CutoffContainer wrap='nowrap' container alignItems='center'>
                  {call.cutoffs.map((cutoff) => 
                    <$CutoffButton
                      clickable={!!call.cutoffs.length && (selectedCutoffId != cutoff.id)}
                      selected={selectedCutoffId == cutoff.id}
                      key={cutoff.id}
                      onClick={() => onCutoffSelect(call.id, cutoff.id || 0)}
                    >
                      {`CutOff ${cutoff.title || ((cutoff.order || 0) + 1)}`}
                    </$CutoffButton>)}
                </$CutoffContainer>
                <$Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
                  {call.flow.map((step, index) => {
                    const cutoffStepInfo = selectedCutoff?.steps[index]
                    const cutoffTimeframe = step.timeframes.find((timeframe) => timeframe.cutoffId == selectedCutoffId)
                    const stepTimeframe = cutoffTimeframe ? 
                      activeTimeFrame({ ...step, timeframes: [cutoffTimeframe]}) : undefined

                    const endTimeframe = cutoffTimeframe?.end ? dateUTC(cutoffTimeframe.end) : undefined
                    
                    const now = dateUTC()
                    const active = !!stepTimeframe
                    const completed = active || !endTimeframe ? false : (endTimeframe < now)
                    const icon = cutoffStepInfo ?
                      cutoffStepInfo.changesRequest.length ? DASHBOARD_STATS.REQUEST_CHANGES :
                        cutoffStepInfo.submission.length ? DASHBOARD_STATS.PENDING_SUBMISSION : 
                          cutoffStepInfo.approval.length ? DASHBOARD_STATS.PENDING_APPROVAL : 
                            cutoffStepInfo.acceptance.length ? DASHBOARD_STATS.PENDING_ACCEPTANCE : active ? 'pending' : 'lockOutlined' : active ? 'pending' : 'lockOutlined'

                    const pendingActions = 
                      (cutoffStepInfo?.submission.length || cutoffStepInfo?.changesRequest.length)
                        || cutoffStepInfo?.approval.length || 
                        (completed ? 0 : cutoffStepInfo?.acceptance.length)
                    return (
                      <Step key={step.id}>
                        <$StepLabel
                          onClick={() => setStepSelection((prev) => ({ ...prev, [call.id]: index }))}
                          StepIconComponent={
                            () => <Tooltip
                              placement='top'
                              show
                              noIcon
                              text={step.title}
                            >
                              {stepIcon(active, completed, !!pendingActions, icon)}
                            </Tooltip>
                          }
                        >
                          {selectedStepIdx == index ? <$Arrow /> : ' '}
                        </$StepLabel>
                      </Step>
                    )})}
                </$Stepper>
                <$StepContainer>
                  <$Content><Title subTitle>{selectedStep?.title}</Title></$Content>
                  {selectedStepTimeframe ? 
                    <div>
                    Timeframe from <$Date>{`${BrowserLocaleDateFormatter.format(new Date(selectedStepTimeframe.start))} ${BrowserLocaleTimeFormatter.format(new Date(selectedStepTimeframe.start))}`}</$Date>
                      <$Date>{selectedStepTimeframe?.end ? ` to ${BrowserLocaleDateFormatter.format(new Date(selectedStepTimeframe.end))} ${BrowserLocaleTimeFormatter.format(new Date(selectedStepTimeframe.end))}` : ' with no end'}</$Date>
                    </div>
                    : null}
                  <$Content>Status: <$Status>{stepActive ? 'Active' : 'Closed'}</$Status></$Content>
                  {selectedStepInfo?.submission.length ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.PENDING_SUBMISSION,
                      step: selectedStep, call, ids: selectedStepInfo.submission })}>
                    <$SvgIcon size='22' primary={!selectedStepInfo.submission.length} name={DASHBOARD_STATS.PENDING_SUBMISSION} />
                    <$Number>{selectedStepInfo.submission.length}/{totalDrafts}</$Number> 
                    {!selectedStepIdx ? 'Drafts' : 'Pending Submissions'}
                  </$Content> : null}
                  {selectedStepInfo?.expiredSubmission.length ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.MISSED_SUBMISSION,
                      step: selectedStep, call, ids: selectedStepInfo.expiredSubmission })}>
                    <$SvgIcon size='22' primary name={DASHBOARD_STATS.MISSED_SUBMISSION} />
                    <$Number>{selectedStepInfo.expiredSubmission.length}/{totalDrafts}</$Number>
                    Missed Submission Deadline
                  </$Content> : null}
                  <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.SUBMITTED,
                      step: selectedStep, call, ids: selectedStepInfo?.submitted })}>
                    <$SvgIcon size='22' primary name={DASHBOARD_STATS.SUBMITTED} />
                    <$Number>{selectedStepInfo?.submitted.length || 0}</$Number> Submitted
                  </$Content>
                  {selectedStepInfo?.changesRequest.length ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.REQUEST_CHANGES,
                      step: selectedStep, call, ids: selectedStepInfo.changesRequest })}>
                    <$SvgIcon size='22' primary={!selectedStepInfo.changesRequest.length} name={DASHBOARD_STATS.REQUEST_CHANGES} />
                    <$Number>{selectedStepInfo.changesRequest.length}/{totalChangesRequested}</$Number>
                    Pending Request Changes
                  </$Content> : null}
                  {selectedStepInfo?.acceptance.length ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.PENDING_ACCEPTANCE,
                      step: selectedStep, call, ids: selectedStepInfo.acceptance })}>
                    <$SvgIcon size='22' primary={!selectedStepInfo.acceptance.length} name={DASHBOARD_STATS.PENDING_ACCEPTANCE} />
                    <$Number>{selectedStepInfo.acceptance.length}/{totalAccepts}</$Number> Pending Acceptance
                  </$Content> : null}
                  {hasApproval && (selectedStepInfo?.approval.length) ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.PENDING_APPROVAL,
                      step: selectedStep, call, ids: selectedStepInfo.approval })}>
                    <$SvgIcon size='22' primary={!selectedStepInfo.approval.length} name={DASHBOARD_STATS.PENDING_APPROVAL} />
                    <$Number>
                      {selectedStepInfo.approval.length}/{totalApprovals}</$Number>
                      Pending Approval
                  </$Content> : null}
                  {hasApproval ? <$Content onClick={() => 
                    navigateWithState({ name: DASHBOARD_STATS.APPROVED,
                      step: selectedStep, call, ids: selectedStepInfo?.approved })}>
                    <$SvgIcon size='22' primary name={DASHBOARD_STATS.APPROVED} />
                    <$Number>{selectedStepInfo?.approved.length || 0}</$Number> Approved
                  </$Content> : null}
                  {/* <$Content onClick={() => //TODO: add conflict of interest when it is revamped
                    navigateWithState({ name: DASHBOARD_STATS.CONFLICT_INTEREST,
                      step: selectedStep, call, ids: selectedStepInfo?.conflicted })}>
                    <$SvgIcon size='22' primary={!selectedStepInfo?.conflicted.length} 
                    name={DASHBOARD_STATS.CONFLICT_INTEREST} />
                    <$Number>{selectedStepInfo?.conflicted.length || 0}</$Number> Conflicted
                  </$Content> */}
                </$StepContainer>
              </$CallContainer>
            )})}
        </div>
        {loading && <Grid container justifyContent='center'>
          <CircularProgress size={30} />
        </Grid>}
        {!!hasMore && !loading && <div ref={loadMoreRef}>...</div>}
      </$Calls>
    </>
  )
}
