import * as React from 'react'
import update from 'immutability-helper'
import { $GridFlow, $GridCollapseContainer } from '../styles'
import { SortableCollapseFlow, OnMove, CollapseItem, ELEMENT_TYPES, FlowStep, FlowForm, NewLoading } from 'prace-common-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useUpdateCallMutation } from 'store/api/calls'
import { useCreateFlowElementsMutation, useUpdateElementMutation } from 'store/api/calls/elements'
import { getElement } from 'util/getElement'
import { CallElement } from 'store/api/calls/elements/types'

type DragForm = FlowForm & {origin?: number}
type DragStep = Omit<FlowStep, 'flow'> & {flow: DragForm[]; origin?: number}

export const CallFlow: React.FC = () => {
  const dispatch = useAppDispatch()
  const [updateCall] = useUpdateCallMutation()
  const [updateElement, {isLoading}] = useUpdateElementMutation()
  const [createFlowElements] = useCreateFlowElementsMutation()
  const call = useAppSelector((state: RootState) => state.calls.call)
  const step = useAppSelector((state: RootState) => state.calls.editorSelector.step)
  const form = useAppSelector((state: RootState) => state.calls.editorSelector.form)
  const [steps, setSteps] = React.useState<DragStep[]>(call.flow)

  React.useEffect(() => setSteps(call.flow), [call.flow])

  // TODO: Should select using Id
  const handleSelect = (order: number, type: string) => {
    dispatch({ type: 'selectElement', payload: { order, type: type as ELEMENT_TYPES }})
  }

  const handleAdd = async (type: string, index: number) => {
    //dispatch(EDIT_CALL_EVENTS.ADD_ELEMENT, { type: type as ELEMENT_TYPES, index })
    if(type === ELEMENT_TYPES.STEP) {
      const flow = call.flow
      const newElement = getElement<FlowStep>(call.id, null, flow.length, type)

      let newElements: CallElement[] = await createFlowElements({
        callId: (newElement as FlowStep).callId,
        flowElements: [newElement],
      }).unwrap()
      newElements = newElements.map((el) => {
        let newFlow: FlowForm[] = []
        if(el.flow)
          newFlow = el.flow.map((flowEl) => ({ ...flowEl, flow: flowEl.flow || [] })) as FlowForm[]
        return { ...el, flow: newFlow } as CallElement
      })
      //store.dispatch(EDIT_CALL_EVENTS.CREATE_ELEMENTS_SUCCESS, { state: editCall, newElements })
      dispatch({ type: 'addSteps', payload: { newElements }})
    } else { // FORM
      const flowStep = call.flow[index || 0]
      const flow = flowStep.flow
      const newElement = getElement<FlowForm>(null, flowStep.id, flow.length, type)

      let newElements: CallElement[] = await createFlowElements({
        parentId: (newElement as FlowForm).parentId,
        flowElements: [newElement],
      }).unwrap()
      newElements = newElements.map((el) => {
        let newFlow: FlowForm[] = []
        if(el.flow)
          newFlow = el.flow.map((flowEl) => ({ ...flowEl, flow: flowEl.flow || [] })) as FlowForm[]
        return { ...el, flow: newFlow } as CallElement
      })
      dispatch({ type: 'addForms', payload: { stepId: flowStep.id, newElements }})
      //store.dispatch(EDIT_CALL_EVENTS.CREATE_ELEMENTS_SUCCESS, { state: editCall, newElements })
    }
    /* store.dispatch(EDIT_CALL_EVENTS.CREATE_ELEMENTS_API, {
      callId: type === ELEMENT_TYPES.STEP ? (newElement as FlowStep).callId : undefined,
      parentId: type === ELEMENT_TYPES.STEP ? undefined : (newElement as FlowForm | FlowFormElement).parentId,
      elements: [newElement],
    }) */
  }

  const handleOnDrag = ({
    dragIndex,
    hoverIndex,
    dragType,
    /* dragItem, */
  }: OnMove) => {
    /* dispatch(EDIT_CALL_EVENTS.MOVE_ELEMENT,
      { dragIndex, hoverIndex, dragType: dragType as ELEMENT_TYPES, element: dragItem as Omit<FlowElement, 'flow'> }) */
    if(dragType === ELEMENT_TYPES.STEP) {
      setSteps((prevSteps) => {
        const previewItem = { ...prevSteps[dragIndex], origin: dragIndex }
        const newSteps = update(prevSteps, {
          $splice: [
            [dragIndex, 1], // Remove drag element
            [hoverIndex, 0, previewItem as FlowStep], // Dont remove elements and add element to index
          ],
        }).map((step: FlowStep, i: number) => ({ ...step, order: i }))
        return newSteps
      })
      /* newEditCall = update(editCall, {
        editorSelector: {
          step: { $set: hoverIndex },
          lastSelection: { $set: ELEMENT_TYPES.STEP },
        },
        call: {
          flow: {
            $splice: [
              [dragIndex, 1], // Remove drag element
              [hoverIndex, 0, call.flow[dragIndex] as FlowStep], // Dont remove elements and add element to index
            ]}},
      }) */
    } else { // FORM
      setSteps((prevSteps) => {
        const previewItem = { ...prevSteps[step].flow[dragIndex], origin: dragIndex }
        const newSteps = update(prevSteps, {
          [step]: {
            flow: {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, previewItem as FlowForm],
              ]}},
        }).map((step: FlowStep, i: number) => ({ ...step, order: i }))
        return newSteps
      })
      /* 
      newEditCall = update(editCall, {
          editorSelector: {
            form: { $set: hoverIndex },
            lastSelection: { $set: ELEMENT_TYPES.FORM },
          },
          call: {
            flow: {
              [step]: {
                flow: {
                  $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, call.flow[step].flow[dragIndex] as FlowForm],
                  ]}}}},
        })
      */
    }
  }

  const cleanPreviews = () => {
    setSteps((prevSteps) => {
      const newSteps = prevSteps.map((step) => ({
        ...step,
        flow: step.flow.map((form) => ({ ...form, origin: undefined })),
        origin: undefined,
      }))
      return newSteps
    })
  }

  const handleOnEndDrag = async (type: string) => {
    //dispatch(EDIT_CALL_EVENTS.ELEMENT_MOVEMENT_API, { type: type as ELEMENT_TYPES })
    if(type === ELEMENT_TYPES.STEP) {
      try {
        const newStepSelect = steps.findIndex((step) => step.origin !== undefined)
        const newFlow = steps.map((step, i) => ({...step, order: i }))
        const ordersFlow = newFlow.map((flowStep: FlowStep) => ({
          id: flowStep.id,
          order: flowStep.order,
        }))
        await updateCall({
          ...call,
          flow: ordersFlow ? ordersFlow as FlowStep[] : [],
        })
        dispatch({ type: 'updateCall', payload: { call: {...call, flow: newFlow}, newStepSelect } })
        cleanPreviews()
      } catch (err) {
        dispatch({ type: 'updateCall', payload: {} })
        console.log(err)
      }
    } else { // FORM
      try {
        const flow = steps[step].flow as DragForm[]
        const newFormSelect = flow.findIndex((form) => form.origin !== undefined)
        const newFlow = flow.map((form, i) => ({...form, order: i }))
        const newValues = {...steps[step], flow: newFlow ? newFlow as FlowForm[] : []}
        await updateElement(newValues)
        dispatch({ type: 'updateStep', payload: { newValues, newFormSelect } })
        cleanPreviews()
      } catch (err) {
        dispatch({ type: 'updateCall', payload: {} })
        console.log(err)
      }
    }
  }

  return (
    <$GridFlow item xs={3}>
      <NewLoading loading={isLoading} />
      <$GridCollapseContainer>
        <SortableCollapseFlow
          sortable
          flow={steps as CollapseItem[]}
          selectedCollapse={{ order: step, type: ELEMENT_TYPES.STEP }}
          selectedElement={{ order: form, type: ELEMENT_TYPES.FORM }}
          handleSelect={handleSelect}
          handleAdd={handleAdd}
          handleOnDrag={handleOnDrag}
          handleOnEndDrag={handleOnEndDrag}
        />
      </$GridCollapseContainer>
    </$GridFlow>
  )
}
