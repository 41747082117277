import { FORM_ELEMENT_TYPES, ELEMENT_TYPES } from '../newFormElement';
import { USER_INFO, USER_INFO_ITEMS } from '../constants/global';
export const getElementOptions = (element) => {
    const { elementType, type, title, order } = element;
    const exportAliasConfig = { title: 'Alias of the element to export (Beta)', value: element.config?.exportAlias, name: 'exportAlias', type: FORM_ELEMENT_TYPES.TEXT };
    const flowElementType = elementType ? elementType : type;
    const error = (title?.length < 3 || !title);
    const titleProps = {
        error,
        errorMsg: error ? 'Title must have at least 3 characters' : undefined,
    };
    switch (flowElementType) {
        case ELEMENT_TYPES.FORM: {
            return [
                { title: 'Form title', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Form description', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT_AREA },
            ];
        }
        case ELEMENT_TYPES.STEP: {
            const stepOptions = [
                { title: 'Step title', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Admin Check', value: element.config?.hasAdminCheck, name: 'hasAdminCheck', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Was this step checked by an admin?' },
                { title: 'Unlockable', value: element.config?.isUnlockable, name: 'isUnlockable', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Is this step unlockable?' },
                { title: 'Automatic Accept', value: element.config?.automaticAcceptance, name: 'automaticAcceptance', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Are assignments automatically accepted?' },
                { title: 'Has lead', value: element.config?.hasLead, name: 'hasLead', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Does this step have a lead user?' },
                { title: 'Has reminders', value: element.config?.hasReminder, name: 'hasReminder', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Does this step assignments have deadline reminders?' },
                { title: 'Show COI', value: element.config?.showCoi, name: 'showCoi', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Must the user accept the conflict of interest?' },
                { title: 'COI Text', value: element.config?.coi, name: 'coi', type: FORM_ELEMENT_TYPES.TEXT_AREA, description: 'What is the conflict of interest text?' },
                { title: 'Assignment Submit Confirmation', value: element.config?.submitConfirmation, name: 'submitConfirmation', type: FORM_ELEMENT_TYPES.CHECKBOX, description: 'Must the user confirm before submitting an assignment?' },
            ];
            if (!order)
                return stepOptions.filter((option) => !['submitConfirmation', 'hasLead', 'isUnlockable'].includes(option.name));
            else
                return stepOptions;
        }
        case FORM_ELEMENT_TYPES.TEXT_AREA: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                { title: 'Minimum number of characters', value: element.config?.min, name: 'min', type: FORM_ELEMENT_TYPES.NUMBER },
                { title: 'Maximum number of characters', value: element.config?.max, name: 'max', type: FORM_ELEMENT_TYPES.NUMBER },
                exportAliasConfig,
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.NUMBER: {
            const min = element.config?.min;
            const max = element.config?.max;
            const error = min && max ? max < min : false;
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                { title: 'Minimum value',
                    value: min,
                    name: 'min',
                    decimal: element.config?.decimal,
                    type: FORM_ELEMENT_TYPES.NUMBER, },
                { title: 'Maximum value',
                    value: max,
                    decimal: element.config?.decimal,
                    name: 'max',
                    type: FORM_ELEMENT_TYPES.NUMBER,
                    error,
                    errorMsg: error ? 'Maximum must be higher than minimum' : undefined, },
                exportAliasConfig,
                { title: 'Allow decimal numbers', name: 'decimal', value: element.config?.decimal, type: FORM_ELEMENT_TYPES.CHECKBOX },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.CHECKBOX: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                exportAliasConfig,
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.UPLOAD: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.TITLE: {
            return [
                { title: 'Title', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
            ];
        }
        case FORM_ELEMENT_TYPES.INSTRUCTIONS: {
            return [
                { title: 'Text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT_AREA, required: true },
            ];
        }
        case FORM_ELEMENT_TYPES.DATE: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                { title: 'Minimum date', value: element.config?.min || undefined, name: 'min', type: FORM_ELEMENT_TYPES.DATE, required: false },
                { title: 'Maximum date', value: element.config?.max || undefined, name: 'max', type: FORM_ELEMENT_TYPES.DATE, required: false },
                exportAliasConfig,
                { title: 'Autofill with user info',
                    value: element.config?.autofill, name: 'autofill', type: FORM_ELEMENT_TYPES.SELECT,
                    items: USER_INFO_ITEMS.filter((f) => [USER_INFO.DateOfBirth].includes(f.value)), required: false, placeholder: 'Select user info', },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.PHONE_NUMBER: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                exportAliasConfig,
                { title: 'Autofill with user info',
                    value: element.config?.autofill, name: 'autofill', type: FORM_ELEMENT_TYPES.SELECT,
                    items: USER_INFO_ITEMS.filter((f) => f.value === USER_INFO.PhoneNumber), required: false, placeholder: 'Select user info', },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.PARTITION_RESOURCES:
        case FORM_ELEMENT_TYPES.SELECT_PARTITION: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                exportAliasConfig,
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        case FORM_ELEMENT_TYPES.TOGGLE:
        case FORM_ELEMENT_TYPES.MULTISELECT:
        case FORM_ELEMENT_TYPES.SELECT: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                { title: 'Existing Options Groups', value: element.optionsGroup?.tag, name: 'options', type: FORM_ELEMENT_TYPES.SELECT, items: element.items, required: true, placeholder: 'Select options group' },
                exportAliasConfig,
                { title: 'Autofill with user info',
                    value: element.config?.autofill, name: 'autofill', type: FORM_ELEMENT_TYPES.SELECT,
                    items: USER_INFO_ITEMS.filter((f) => [USER_INFO.Title, USER_INFO.Gender, USER_INFO.Nationality, USER_INFO.Fields].includes(f.value)), required: false, placeholder: 'Select user info', },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
        default: {
            return [
                { title: 'Label', value: title, ...titleProps, name: 'title', type: FORM_ELEMENT_TYPES.TEXT, required: true },
                { title: 'Helper text', value: element.description, name: 'description', type: FORM_ELEMENT_TYPES.TEXT },
                exportAliasConfig,
                { title: 'Autofill with user info',
                    value: element.config?.autofill, name: 'autofill', type: FORM_ELEMENT_TYPES.SELECT,
                    items: USER_INFO_ITEMS, required: false, placeholder: 'Select user info', },
                { title: 'Make this field required', name: 'required', value: element.config?.required, type: FORM_ELEMENT_TYPES.CHECKBOX },
            ];
        }
    }
};
