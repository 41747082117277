import React, { FC, useEffect, useState } from 'react'
import update from 'immutability-helper'
import { useNavigate, useParams, useBlocker, BlockerFunction } from 'react-router-dom'
import { ROLES, getSelectedStepAssignment, applicationCutOff, NotFound404, ErrorNotice, FlowCall, CallPartitionResource, NewModal, canEditAssignment, FlowAssignment, User, FlowFormElement, FLOW_STATUS, ApplicationFlowStep, ApplicationCall, ApplicationFormElement, CollapseItem, ELEMENT_TYPES, ElementValue, newTheme, AssignmentData, validateAssignment, ApiBaseError, AssignmentInput, NotificationType, NewLoading as Loading, activeCutOff, FlowStep, DnDWrapper, COIModal, ConfirmAction, ConfirmModal, FORM_ELEMENT_TYPES, GroupInstance, NewButton, stepTimeFrame, FlowApplication, ApplicationDocument, ApplicationStep, transformForms, AssignmentDataUpload, getGroupsToCreate, getConditionsValues, ADMIN_PORTAL_ROLES } from 'prace-common-components'
import {
  $CallApplication,
  $Chip,
  $PageTitle,
  $ProposalID,
  $CallImage,
  $EditIcon,
  $NestedMenu,
  $FormContainer,
} from './styles'
import Grid from '@material-ui/core/Grid'
import { Banner } from 'components/Banner'
import { RejectModal } from 'components/RejectModal'
import { AssignModal } from 'components/AssignModal'
import {
  useAcceptAssignmentMutation,
  useAssignMutation,
  useChangeNextCutoffMutation,
  useChangeUserVisibilityMutation,
  useDeleteAssignmentDataMutation,
  useDeleteUploadMutation,
  useGetApplicationQuery,
  useGetDocumentsQuery,
  useListOptionsQuery,
  useReviewAssignmentMutation,
  useSaveAssignmentMutation,
  useSubmitAssignmentMutation,
  useUnlockMutation,
  useUpdateCoiDataMutation,
  useUpdateCommentDataMutation,
  useUpdateDeadlineMutation,
  useUpdateLeadAssignmentMutation,
  useUpdateRevokedMutation,
  useUploadFileMutation,
} from 'store/api/applications'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useLazyDownloadPdfQuery, useLazyDownloadFileQuery } from 'store/api/downloads'
import { CDN, Routes } from 'constants/global'
import { InviteModal } from 'components/InviteModal'
import { useInviteMutation } from 'store/api/auth'
import { useSearchUsersMutation } from 'store/api/users'
import { UserSuggestion } from 'store/api/users/types'
import { AssignmentFlowContainer } from './AssignmentFlowContainer'
import { CallFlowContainer } from './CallFlowContainer'

export interface CallApplicationState extends Omit<FlowApplication, 'call' | 'user'> {
  call?: ApplicationCall,
  id: FlowApplication['id'],
  uid: FlowApplication['uid'],
  user?: FlowApplication['user'],
  ownerId: number,
  unlocked: FlowApplication['unlocked'],
  needSave: boolean,
  editorSelector: {
    step: number,
    form: number,
    assignment: number,
    workingStep: number,
  },
}

const menuItems = [
  {id: 0, label: <>Change to next cutoff</>},
]

export const CallApplication: FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const navigate = useNavigate()
  const { uid } = useParams<{ uid: string }>()
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useAppDispatch()
  const [downloadFile] = useLazyDownloadFileQuery()
  const [downloadPdf] = useLazyDownloadPdfQuery()
  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation()
  const [deleteUpload, { isLoading: isDeletingUpload }] = useDeleteUploadMutation()
  const [acceptAssignment, { isLoading: isAccepting }] = useAcceptAssignmentMutation()
  const [assignUser, { isLoading: isAssigning }] = useAssignMutation()
  const [submitAssignment, { isLoading: isSubmitting }] = useSubmitAssignmentMutation()
  const [updateLeadAssignment, { isLoading: isUpdatingLead }] = useUpdateLeadAssignmentMutation()
  const [updateDeadline, { isLoading: isUpdatingDeadline }] = useUpdateDeadlineMutation()
  const [unlock, { isLoading: isUnlocking }] = useUnlockMutation()
  const [revoke, { isLoading: isRevoking }] = useUpdateRevokedMutation()
  const [reviewAssignment, { isLoading: isReviewing }] = useReviewAssignmentMutation()
  const [deleteAssignmentData, { isLoading: isDeletingData }] = useDeleteAssignmentDataMutation()
  const [saveAssignment, { isLoading: isSaving }] = useSaveAssignmentMutation()
  const [invite, { isLoading: isInviting }] = useInviteMutation()
  const [changeVisibility, { isLoading: changingVisibility }] = useChangeUserVisibilityMutation()
  const [updateCoiData, { isLoading: updatingCoi }] = useUpdateCoiDataMutation()
  const [updateCommentData, { isLoading: updatingComment }] = useUpdateCommentDataMutation()
  const [changeToNextCutoff, { isLoading: changingCutoff }] = useChangeNextCutoffMutation()
  const [searchUsers] = useSearchUsersMutation()
  const [usersSR, setUsersSR] = React.useState<UserSuggestion[]>([])
  const [rejectOpen, setRejectOpen] = useState<boolean>(false)
  const [assignOpen, setAssignOpen] = useState<boolean>(false)
  const [cutoffOpen, setCutoffOpen] = useState<boolean>(false)
  const [inviteStepOpen, setInviteStepOpen] = useState<boolean>(false)
  const [inviteOpen, setInviteOpen] =
    useState<{firstName: string; lastName: string; email: string} | undefined>(undefined)
  const [COIOpen, setCOIOpen] = React.useState<{
    coi: boolean,
    comment:string,
    elementIdx: number,
    groupInstanceIdx: number,
    flowElementId: ApplicationFormElement['id'],
  } | undefined>(undefined)
  const [assignOtherOpen, setAssignOtherOpen] = useState<boolean>(false)
  const customLoading = useAppSelector((state: RootState) => state.loading.value)

  const [callApplication, setCallApplication] = useState<CallApplicationState>({
    id: 0,
    uid: '',
    ownerId: 0,
    unlocked: [],
    call: undefined,
    needSave: false,
    editorSelector: {
      step: 0,
      form: 0,
      assignment: 0,
      workingStep: 0,
    },
  })
  const [documents, setDocuments] = useState<ApplicationDocument[]>([])
  const [draft, setDraft] = useState<boolean>(false)

  const { step = 0, form = 0, assignment = 0, workingStep = 0 } = callApplication.editorSelector
  const call = callApplication?.call as ApplicationCall
  const user = callApplication?.user as User
  const flowStep = call?.flow[step] || {}
  const {assignments = [], permissions = { assign: [], assignable: [] }} = flowStep
  const { data: apiApplication, isLoading, isFetching, isUninitialized, error, refetch } = useGetApplicationQuery(
    uid || '', { skip: !uid, refetchOnMountOrArgChange: true })
  const {
    data: apiDocuments,
    isLoading: isLoadingDocs,
    isFetching: isFetchingDocs,
    refetch: refetchDocs,
  } = useGetDocumentsQuery(callApplication.id)
  const { data: optionsGroupsData, isFetching: loadingOptions } = useListOptionsQuery({ offset: 0 })
  const options_groups = optionsGroupsData?.data || []
  const needSave = callApplication.needSave


  const loading = isLoading || isFetching || isUninitialized || loadingOptions || customLoading ||
    isAccepting || isUploadingFile || isDeletingUpload || isUpdatingDeadline ||
    isAssigning || isSubmitting || isUpdatingLead || isDeletingData || isUnlocking ||
    isDeletingData || isSaving || isLoadingDocs|| isFetchingDocs || isRevoking ||
    isReviewing || isInviting || changingVisibility || updatingCoi || updatingComment ||
    changingCutoff

  useEffect(() => {
    const handleBeforeUnload = (e: { preventDefault: () => void; returnValue: string }) => {
      if (needSave) {
        e.preventDefault()
        e.returnValue = '' // required for chrome
        return true // Return a truthy value
      }
      return null // Allow navigation if no conditions met
    }
  
    window.addEventListener('beforeunload', handleBeforeUnload)
  
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [needSave])

  const shouldBlockNavigation: BlockerFunction = ({ currentLocation, nextLocation }) => {
    if (needSave) {
      /* Blocks if request is going to the same destination (refresh and closing), user can still go to other routes */
      /* TODO: Improve this to block routing to other routes too */
      return currentLocation.pathname === nextLocation.pathname
    }
    return false // Allow navigation
  }

  useBlocker(shouldBlockNavigation)

  useEffect(() => {
    if(apiApplication)
      setCallApplication((prevApp) => {
        /* If this is not the first time requesting  */
        const sameSelection = prevApp.id == apiApplication.id
        const {
          editingAssign,
          editing,
          moreAdvancedAssignment,
          newDraft,
          updatedApplication,
        } = getSelectedStepAssignment(apiApplication.user as User, apiApplication)
        const selects = editingAssign ? editing : moreAdvancedAssignment
        const step = sameSelection ? prevApp.editorSelector.step : selects.step
        const assignment = sameSelection ? prevApp.editorSelector.assignment : selects.assignment
        const flowStep = updatedApplication.call.flow[step] as ApplicationStep
        let newApplication = updatedApplication
        if(flowStep?.flow) {
          newApplication = update(updatedApplication, {
            call: {
              flow: {
                [step]: {
                  flow: { $set: transformForms(flowStep, assignment) },
                }}}})
        }
        setDraft(newDraft)
        return {
          ...newApplication,
          needSave: false,
          editorSelector: sameSelection ? prevApp.editorSelector :
            { ...prevApp.editorSelector, step, assignment, workingStep: step },
        }
      })
  
  }, [apiApplication])
  
  useEffect(() => {
    setDocuments(apiDocuments || [])
  }, [apiDocuments])

  /* FIXME: Make a BE endpoint that sends this, and only do the request when needed (inviting)  */
  //FIXME: Prevent doing this request everytime we change step
  useEffect(() => {
    const getSRUsers = async () => {
      const flowForm = flowStep?.flow ? flowStep?.flow[form] : undefined
      const formElements = flowForm?.flow || []
      const search: string[] = []
      const groupsSR = formElements.filter((element) => element.elementType === FORM_ELEMENT_TYPES.GROUP &&
      (element.title || '').toLocaleLowerCase().includes('scientific') && (element.title || '').toLocaleLowerCase().includes('reviewer'))
      if(groupsSR.length) {
        for(let i = 0; i < groupsSR.length; i++) {
          const instances = groupsSR[i].instances || []
          for(let j = 0; j < instances.length; j++) {
            const childs = instances[j].childInstances
            const childEmail = childs.find(
              (childEl) => childEl.elementType == FORM_ELEMENT_TYPES.EMAIL && !!childEl.value)
            if(childEmail) search.push(String(childEmail.value))
          }
        }
        if(search.length) {
          const data = await searchUsers({
            search,
            roles: [ROLES.SCIENTIFIC_REVIEWER],
            applicationId: callApplication.id,
            page: 0,
            pageSize: 20,
          }).unwrap()
          setUsersSR((data.users || []) as unknown as UserSuggestion[])
        }
      }
    }
    getSRUsers().catch((err) => console.log(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, flowStep.flow])

  const changeSelection = (order: number, type: string) => {
    const isStep: boolean = type === 'step'
    const isAssignment: boolean = type === 'assignment'
    setCallApplication((prevApp) => {
      const { step, assignment } = prevApp.editorSelector
      if(!prevApp.call) return prevApp
      const flowStep: ApplicationStep = isStep ? prevApp.call.flow[order] : prevApp.call.flow[step]
      let selectAssignment = 0
      if(isStep) { // Find the user assignment, if there aren't just show first
        for(let i = 0; i < (flowStep.assignments || []).length; i++) {
          if(flowStep.assignments[i]?.ownerId == prevApp.user?.id) selectAssignment = i
        }
      }
      let newCall = prevApp.call
      if(type !== 'form') {
        newCall = update(prevApp.call, {
          flow: {
            [isStep ? order : step]: {
              flow: { $set: transformForms(flowStep, isStep ? selectAssignment : isAssignment ? order : assignment) },
            }}})
      }
      return update(prevApp, {
        call: { $set: newCall },
        editorSelector: {
          form: { $set: isStep || isAssignment ? 0 : prevApp.editorSelector.form },
          assignment: { $set: isStep ? selectAssignment : prevApp.editorSelector.assignment },
          [type]: { $set: order },
        },
      })})
  }

  //TODO: This handler uses currentassignment
  //TODO: Find way to do this changeFile in a better way
  const onChangeFile = async (
    childElementId: number,
    file: Nullable<File>,
    uploadId?: number,
    groupElementOrder?: number,
    groupId?: number,
    instance?: number,
  ) => {
    if(!currentAssignment) return
    const foundDataGroup = currentAssignment.data.find(
      (data: AssignmentData) => ((data.flowElementId == groupId) && (data.input?.order == instance)))
    let foundDataGroupId = foundDataGroup?.id
    if(file) {
      let assignmentDatas = currentAssignment.data

      /* Save everything before uploading file to make sure we have everything synced */
      if((!foundDataGroup || foundDataGroup.feId || foundDataGroup.needCreate) && groupId && childElementId) {
        const { newGroups, allSavedIds } = getGroupsToCreate(currentAssignment.data, foundDataGroupId)
        //Only send assignmentDatas with needSave flag
        const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
        allSavedIds.push(...saveDatas.map((d) => d.id))
        const newDatas = await saveAssignmentApi(currentAssignment.id, allSavedIds, false, saveDatas, newGroups)
        if(!newDatas) return
        foundDataGroupId = newDatas?.find((d) =>
          (d.flowElementId == groupId) && (d.input?.order == instance))?.id
        assignmentDatas = [...currentAssignment.data.filter(
          (d) => !((d.flowElementId == groupId) && (d.input?.order == instance))), ...newDatas]
      }

      const data = new FormData()
      data.append('files', file)
      data.append('flowElementId', String(childElementId))

      if(childElementId && foundDataGroupId && groupId) { // Found Group element
        data.append('groupId', String(foundDataGroupId))
        const uploadAssignmentData = await uploadFile({ id: currentAssignment.id, data }).unwrap()
        const uploadId = uploadAssignmentData.upload?.id
        const foundDataIdx = assignmentDatas.findIndex(
          (data: AssignmentData) => data.id === uploadAssignmentData.id)
        if(foundDataIdx > -1 && uploadAssignmentData?.id) { // See if assignmentData exists
          setCallApplication((prevApp) => changeGroupAssignmentValue(
            prevApp,
            groupId,
            groupElementOrder!,
            uploadAssignmentData.id!,
            instance!,
            String(uploadId),
            FORM_ELEMENT_TYPES.UPLOAD,
          ))
        } else {
          setCallApplication((prevApp) => addGroupAssignmentData(prevApp,
            [uploadAssignmentData],
            groupId,
            groupElementOrder!,
            instance!,
            FORM_ELEMENT_TYPES.UPLOAD,
            String(uploadId),
            true,
          ))
        }
        setDocuments((prevDocs) => {
          const filteredDocs = prevDocs.filter((doc) => String(doc.id) != String(uploadId)) || []
          filteredDocs.push(uploadAssignmentData.upload as ApplicationDocument)
          return filteredDocs
        })
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File uploaded' } })
      } else { // Normal element
        const uploadAssignmentData = await uploadFile({ id: currentAssignment.id, data }).unwrap()
        const uploadId = uploadAssignmentData.upload?.id
        const foundData = assignmentDatas.find(
          (data: AssignmentData) => data.flowElementId === childElementId)
        if(foundData) { // See if assignmentData exists
          onChangeValue(String(uploadId), childElementId, false, uploadAssignmentData)
        } else {
          addAssignmentData(uploadAssignmentData, childElementId, String(uploadId))
        }
        setDocuments((prevDocs) => {
          const filteredDocs = prevDocs.filter((doc) => String(doc.id) != String(uploadId)) || []
          filteredDocs.push(uploadAssignmentData.upload as ApplicationDocument)
          return filteredDocs
        })
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File uploaded' } })
      }
    } else {
      const document = documents.find((document) => document.id == uploadId)
      if(!document || !uploadId || !currentAssignment) return
      const updatedData = await deleteUpload({ id: currentAssignment.id, uploadId }).unwrap()
      if(foundDataGroupId && groupId) {
        setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== document.id))
        setCallApplication((prevApp) => {
          const { step, form, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!prevApp.call || !currentAssignment || (instance === undefined)) return prevApp
          const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
            (el: ApplicationFormElement) => el.id === groupId)
          const updatedDatas = currentAssignment.data.filter( // Remove childData
            (data) => !(data.flowElementId === childElementId && data.dataGroupId === foundDataGroupId))
          updatedDatas.push(updatedData)
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: updatedDatas },
                    },
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [elementIdx]: {
                          instances: {
                            [instance]: {
                              childInstances: {
                                [groupElementOrder!]: {
                                  value: { $set: null },
                                  elementType: { $set: FORM_ELEMENT_TYPES.UPLOAD },
                                }}}}}}}},
                },
              },
            },
          })})
      } else {
        /* Remove filename from flow element value */
        setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== document.id))
        setCallApplication((prevApp) => {
          const { step, form, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!currentAssignment || (instance === undefined)) return prevApp
          const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
            (el: ApplicationFormElement) => el.id === groupId)
          const updatedDatas = currentAssignment.data.filter(
            (assignmentData) => assignmentData.flowElementId !== childElementId)
          updatedDatas.push(updatedData)
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: updatedDatas },
                    },
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [elementIdx]: {
                          value: { $set: null }}}}},
                },
              },
            },
          })})
      }
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'File deleted' } })
    }
  }

  const changeGroupAssignmentValue = (
    prevApp: CallApplicationState,
    groupId: number,
    groupElementOrder: number,
    assignmentDataId: number | AssignmentData,
    instance: number,
    value: ElementValue,
    elementType: FORM_ELEMENT_TYPES,
    upload?: AssignmentDataUpload,
    needSave = true,
    updatedData?: Partial<AssignmentData>,
  ) => {
    const values = Array.isArray(value) ? value : [String(value)]
    const { step, form, assignment } = prevApp.editorSelector
    if(!prevApp.call) return prevApp
    const currentAssignment = prevApp.call.flow[step].assignments[assignment]
    const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
      (el: ApplicationFormElement) => el.id === groupId)
    /* If the assignment data is not present in the db, we have no id yet */
    const assignmentDataIdx = (typeof assignmentDataId === 'object') ? 
      currentAssignment?.data.findIndex(
        (data) => (data.flowElementId === assignmentDataId.flowElementId) && 
        (data.dataGroupId === assignmentDataId.dataGroupId))
      : currentAssignment?.data.findIndex((data) => data.id === assignmentDataId)
    if(!currentAssignment || elementIdx === -1 ||  assignmentDataIdx === -1) return prevApp
    return update(prevApp, {
      needSave: { $set: needSave ? true : prevApp.needSave },
      call: {
        flow: {
          [step]: {
            assignments: {
              [assignment]: {
                data: { // Change the corresponding AssignmentData for the BE
                  $apply: (datas: AssignmentData[]) => {
                    datas.splice(assignmentDataIdx, 1, {
                      ...datas[assignmentDataIdx],
                      needSave,
                      input: {
                        ...datas[assignmentDataIdx].input,
                        elementType,
                        values,
                        upload,
                      } as AssignmentInput,
                      ...(updatedData || {}),
                    })
                    return datas
                  }}},
            },
            flow: {
              [form]: {
                flow: {
                  [elementIdx]: {
                    instances: {
                      [instance]: {
                        childInstances: {
                          [groupElementOrder]: {
                            value: { $set: value },
                            elementType: { $set: elementType },
                          }}}}}}}}}},
      },
    })
  }

  const onChangeValue = (
    value: ElementValue,
    flowElementId: FlowFormElement['id'],
    needSave = true,
    updatedData?: AssignmentData,
  ) => {
    const toSave = value === null ? '' : value
    const saveValue = Array.isArray(toSave) ? toSave : [String(toSave)]
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const flowStep = prevApp.call!.flow[step]
      const elementIdx = flowStep.flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === flowElementId)

      if(!prevApp.call?.flow || elementIdx === -1) return prevApp

      let updatedApplication = prevApp

      /* Check if there are elements that have sum conditions needed to be updated */
      //TODO: Do the same for onchangeGroupElement?
      /* TODO: Group elements with sums are not being checked!  */
      for (let f = 0; f < flowStep.flow.length; f++) {
        const flowForm = flowStep.flow[f]
        const foundSums = flowForm.flow.filter((el) =>
          !!el.effects.find((condition) => condition.effect === 'sum')?.validation?.elementsIds?.includes(flowElementId) ||
          !!el.effects.find((condition) => condition.effect === 'sum')?.validation?.elements?.map((e) => e.id).includes(flowElementId),
        )
        if(foundSums.length > 0) {
          for (let i = 0; i < foundSums.length; i++) {
            const foundSumIdx = flowForm.flow.findIndex((el) => el.id === foundSums[i].id)
            const foundSum = flowForm.flow[foundSumIdx]
            const newConditionValues = getConditionsValues(
              prevApp.call, foundSum, prevApp.call.flow[step].id)
            const { sum } = newConditionValues // Recompute sum value
            updatedApplication = update(updatedApplication, {
              call: {
                flow: {
                  [step]: {
                    assignments: {
                      [assignment]: {
                        data: {
                          $apply: function(datas: AssignmentData[]) {
                            const foundData = (datas || []).findIndex((data) => (data.flowElementId === foundSum.id))
                            const newDatas = [...(datas || [])]
                            if(foundData === -1)
                              newDatas.push(
                                { order: newDatas.length + 1,
                                  flowElementId: foundSum.id,
                                  needSave,
                                  input: { values: [String(sum)] } as AssignmentInput,
                                } as AssignmentData)
                            else newDatas.splice(foundData, 1, {
                              ...newDatas[foundData],
                              needSave,
                              input: { ...newDatas[foundData].input, values: [String(sum)] } as AssignmentInput,
                            })
                            return newDatas
                          }}},
                    },
                    flow: {
                      [f]: {
                        flow: {
                          [foundSumIdx]: {
                            value: { $set: sum }}}}}}},
              },
            })
          }
        }
      }
          
      return update(updatedApplication, {
        needSave: { $set: needSave ? true : updatedApplication.needSave },
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: { // Change the corresponding AssignmentData for the BE
                    $apply: function(datas: AssignmentData[]) {
                      const foundData = (datas || []).findIndex((data) => data.flowElementId === flowElementId)
                      const newDatas = [...(datas || [])]
                      if(foundData === -1)
                        newDatas.push(
                          { order: newDatas.length + 1,
                            flowElementId,
                            needSave,
                            input: { values: saveValue } as AssignmentInput,
                          } as AssignmentData)
                      else newDatas.splice(foundData, 1, {
                        ...newDatas[foundData],
                        needSave,
                        input: { ...newDatas[foundData].input, values: saveValue } as AssignmentInput,
                        ...(updatedData || {}),
                      })
                      return newDatas
                    }}},
              },
              flow: {
                [form]: {
                  flow: {
                    [elementIdx]: {
                      value: { $set: value }}}}}}},
        },
      })})
  }

  const addGroupInstance = (
    groupElement: ApplicationFormElement,
    instance: number,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
      const groupElementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === groupElement.id)
      if(!currentAssignment || groupElementIdx === -1) return prevApp
      const assignmentId = currentAssignment.id
      const groupFeId = `create-${groupElement.id}-${instance}`
      const futureGroupData = { id: groupFeId, feId: groupFeId, flowElementId: groupElement.id, needCreate: true, isEditable: true, input: { values: [''], order: instance } as AssignmentInput } as unknown as AssignmentData
      const futureChildrenGroupData = groupElement.flow.map((childEl) => ({
        id: `create-${childEl.id}-${instance}`, feId: `create-${childEl.id}-${instance}`,
        flowElementId: childEl.id, dataGroupId: futureGroupData.id, noData: true, isEditable: true, order: childEl.order, input: { values: [''], order: childEl.order } as AssignmentInput,
      } as unknown as AssignmentData))
      futureChildrenGroupData.push(futureGroupData)
      return update(prevApp, {
        needSave: { $set: true },
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: { $push: futureChildrenGroupData }},
              },
              flow: {
                [form]: {
                  flow: {
                    [groupElementIdx]: {
                      instances: {
                        $push: [{
                          id: instance,
                          flowElementId: groupElement.id,
                          isEditable: futureGroupData.isEditable,
                          assignmentId,
                          childInstances: groupElement.flow.map((child, idx: number) =>
                            ({
                              id: idx,
                              flowElementId: child.id,
                              assignmentId,
                              dataGroupId: futureGroupData.id,
                              dataGroup: { flowElementId: futureGroupData.flowElementId },
                              elementType: child.elementType as FORM_ELEMENT_TYPES,
                              value: undefined,
                              noData: true,
                            }),
                          ),
                        }],
                      }}}}}}}},
      })
    })
  }

  const deleteInstanceAssignmentDataApi = async (
    id: FlowAssignment['id'],
    dataGroupId: number,
    elementId: number,
    instance: number,
    dataIds: number[],
    dataToSave: AssignmentData[],
    updatedDataIds: AssignmentData['id'][],
    fetchDocs?: boolean,
  ) => {
    try {
      await deleteAssignmentData({ id, dataIds }).unwrap()
      if(fetchDocs) refetchDocs()
      /* Update the order of the other instances */
      if(dataToSave.length) await saveAssignment({ id, data: dataToSave, newGroups: [] }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Deletion Successful' } })
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
          (el: ApplicationFormElement) => el.id === elementId)
        if(!currentAssignment || elementIdx === -1) return prevApp
        return update(prevApp, {
          needSave: { $set: true },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    data: { // Remove assignmentDatas
                      $apply: (datas: AssignmentData[]) => {
                        const filteredDatas = datas.filter(
                          (data) => (!dataIds.includes(data.id!) && (data.dataGroupId !== dataGroupId))) || []
                        const updatedDatas = filteredDatas.map((data: AssignmentData) =>
                          (updatedDataIds || []).includes(data.id!) ? 
                            ({
                              ...data, input: { ...data.input, order: (data.input?.order || 1) - 1 },
                            }) as AssignmentData
                            : data)
                        return updatedDatas
                      }}},
                },
                flow: {
                  [form]: {
                    flow: {
                      [elementIdx]: {
                        instances: {
                          $splice: [[instance, 1]], // Remove entire instance
                        }}}}}}},
          },
        })
      })
      
    } catch (err) {
      console.log(err)
    }
  }

  const removeGroupInstance = (
    groupElementId: ApplicationFormElement['id'],
    instance: number,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
      /* Find the instance group data all associated to them */
      const groupData = currentAssignment?.data.find(
        (data: AssignmentData) => (data.flowElementId === groupElementId) && (data.input?.order === instance))
      const elementIdx = prevApp.call!.flow[step].flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === groupElementId)
      if(!currentAssignment || !groupData || (elementIdx === -1)) return prevApp
      /* If we are removing an instance that was not saved to the BE */
      if(groupData.feId) {
        const toRemoveDataFeIds = currentAssignment.data.filter(
          (data: AssignmentData) => data.dataGroupId === groupData.feId)
          .map((childData: AssignmentData) => childData.feId) as string[]
        toRemoveDataFeIds.push(groupData.feId)
        /* Get groupdatas after the one removed */
        const otherInstanceGroupDatas = currentAssignment.data.filter(
          (data: AssignmentData) => data.flowElementId === groupElementId && (data.input?.order || 0) > instance) || []
        const newChildDatas: AssignmentData[] = []
        const toRemoveChildDatasFeIds: string[] = []
        /* Need to update the FE ids of everyone affected */
        for(const groupAssignmentData of otherInstanceGroupDatas) {
          const groupInstance = (groupAssignmentData.input?.order || 1) - 1
          const childDatas = currentAssignment.data.filter(
            (data) => data.dataGroupId === groupAssignmentData.feId)
          toRemoveChildDatasFeIds.push(...((childDatas.map((d) => d.feId) as string[]) || []))
          const updatedChildDatas = childDatas.map((child) => ({
            ...child, id: `create-${child.flowElementId}-${groupInstance}`, feId: `create-${child.flowElementId}-${groupInstance}`,
            dataGroupId: `create-${groupAssignmentData.flowElementId}-${groupInstance}`,
          })) || []
          newChildDatas.push(...(updatedChildDatas as unknown as AssignmentData[]))
        }
        /* Change FE ids of group parents and their order */
        const newGroupDatas = otherInstanceGroupDatas.map((groupD) => ({
          ...groupD, 
          id: `create-${groupD.flowElementId}-${(groupD.input?.order || 1) - 1}`, feId: `create-${groupD.flowElementId}-${(groupD.input?.order || 1) - 1}`,
          input: { ...groupD.input, order: (groupD.input?.order || 1) - 1},
        }))
        const toRemoveGroupDatasFeIds = otherInstanceGroupDatas.map((gd) => gd.feId || '') || []

        toRemoveDataFeIds.push(...toRemoveGroupDatasFeIds, ...toRemoveChildDatasFeIds)
        return update(prevApp, {
          needSave: { $set: true },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    data: { // Remove assignmentDatas
                      $apply: (oldDatas: AssignmentData[]) => {
                        /* Remove the instance that was deleted */
                        const filteredDatas = oldDatas.filter((oldData) => 
                          (!toRemoveDataFeIds.includes(oldData.feId!))) || []
                        /* Update the other group instances by replacing their data by the new */
                        filteredDatas.push(
                          ...(newGroupDatas as unknown as AssignmentData[]), ...newChildDatas)
                        return filteredDatas
                      }}},
                },
                flow: {
                  [form]: {
                    flow: {
                      [elementIdx]: {
                        instances: {
                          $splice: [[instance, 1]], // Remove entire instance
                        }}}}}}},
          },
        })
      } else {
        if(!groupData.id) return prevApp
        const datas = currentAssignment.data.filter(
          (data: AssignmentData) => (data.dataGroupId === groupData.id) && !!data.id)
        const dataIds = datas.map((childData: AssignmentData) => childData.id) as number[]
        dataIds.push(groupData.id as number)

        /* Need to find all assignment datas from the same group but instances above the deleted one */
        const groupDatas = currentAssignment.data.filter(/* Change the order of all instances */
          (data: AssignmentData) => (data.flowElementId === groupElementId) && ((data.input?.order || 0) > instance))
          .map((filteredData: AssignmentData) => (
            { ...filteredData, input: { ...filteredData.input, order: (filteredData.input?.order || 1) - 1}}
          ) as AssignmentData) || []
        const updatedDataIds = groupDatas?.map((d) => d.id) || []
        /* Update the order of all assignment Datas already saved that are after the deleted instance */
        const dataToSave = updatedDataIds?.length > 0 ? groupDatas.filter((data) => !data.feId && !data.needCreate) : []
        /* Request to update BE */
        deleteInstanceAssignmentDataApi(
          currentAssignment.id,
          groupData.id,
          groupElementId,
          instance,
          dataIds,
          dataToSave,
          updatedDataIds,
          !!datas.find((data) => !!data.upload),
        )
        return prevApp
      }
    })
  }

  const addAssignmentData = (
    assignmentData: AssignmentData,
    flowElementId: FlowFormElement['id'],
    value: ElementValue,
  ) => {
    setCallApplication((prevApp) => {
      const { step, form, assignment } = prevApp.editorSelector
      const flowStep = prevApp.call!.flow[step]
      const elementIdx = flowStep.flow[form].flow.findIndex(
        (el: ApplicationFormElement) => el.id === flowElementId)
      if(!prevApp.call || elementIdx === -1) return prevApp
      return update(prevApp, {
        call: {
          flow: {
            [step]: {
              assignments: {
                [assignment]: {
                  data: {
                    $push: [assignmentData],
                  }},
              },
              flow: {
                [form]: {
                  flow: {
                    [elementIdx]: {
                      value: { $set: value }}}}},
            }}},
      })})
  }

  const addGroupAssignmentData = (
    prevApp: CallApplicationState,
    assignmentDatas: AssignmentData[],
    groupId: number,
    groupElementOrder: number,
    instance: number,
    elementType: FORM_ELEMENT_TYPES,
    value: ElementValue,
    maintainNeedSave?: boolean,
  ) => {
    const { step, form, assignment } = prevApp.editorSelector
    if(!prevApp.call?.flow) return prevApp
    const elementIdx = prevApp.call.flow[step].flow[form].flow.findIndex(
      (el: ApplicationFormElement) => el.id == groupId)
    if(elementIdx < 0) return prevApp
    return update(prevApp, {
      needSave: { $set: maintainNeedSave ? prevApp.needSave : true},
      call: {
        flow: {
          [step]: {
            assignments: {
              [assignment]: {
                data: {
                  $push: assignmentDatas,
                }},
            },
            flow: {
              [form]: {
                flow: {
                  [elementIdx]: {
                    instances: {
                      [instance]: {
                        childInstances: {
                          [groupElementOrder]: {
                            value: { $set: value },
                            elementType: { $set: elementType },
                          }}}}}}}}}}},
    })
  }

  const onChangeGroupElementValue = async (
    groupId: ApplicationFormElement['id'],
    childElementId: ApplicationFormElement['id'],
    groupElementOrder: ApplicationFormElement['order'],
    instance: number,
    value: ElementValue,
    elementType: FORM_ELEMENT_TYPES,
  ) => {
    try {
      const saveValue = Array.isArray(value) ? value : [String(value || '')]  
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        if(!currentAssignment) return prevApp
        /* Check if the parent instance (Group) as an assignment data */
        const dataGroupId = currentAssignment.data.find(
          (data: AssignmentData) => ((data.flowElementId == groupId) && (data.input?.order == instance)))?.id
        if(dataGroupId) { 
          /* See if there is a child element assignment data associated to this instance */
          const childData = currentAssignment.data.find(
            (data: AssignmentData) => data.flowElementId === childElementId && data.dataGroupId === dataGroupId)
          if(!childData) {/* If not, create an assignment Data */
            const assignmentDatas = [
              {
                flowElementId: childElementId,
                needSave: true,
                dataGroupId,
                order: groupElementOrder,
                isEditable: true,
                input: { values: saveValue, order: groupElementOrder } as AssignmentInput,
              } as unknown as AssignmentData,
            ]
            return addGroupAssignmentData(
              prevApp, assignmentDatas, groupId, groupElementOrder, instance, elementType, value)
          } else {
            return changeGroupAssignmentValue(prevApp,
              groupId, groupElementOrder, childData?.id || childData, instance, value, elementType)
          }
        } else { /* Create the group assignment data to create */
          const flowForm = prevApp.call?.flow[step].flow[form]
          const groupElementIdx = flowForm ? flowForm.flow.findIndex((el) => el.id == groupId) : -1
          const groupElement = groupElementIdx > -1 ? flowForm?.flow[groupElementIdx] : undefined
          if(!groupElement || groupElementIdx === -1) {
            console.log('No group element found')
            return prevApp
          }
          const groupFeId = `create-${groupId}-${0}`
          const parentData = { id: groupFeId, feId: groupFeId, flowElementId: groupId, needCreate: true, input: { values: [''], order: 0 } as AssignmentInput } as unknown as AssignmentData
          const childDatas = (groupElement.flow || [])
            .filter((child) => !(child as ApplicationFormElement).config?.hidden)
            .map((child, order) => ({ id: `create-${child.id}-${0}`, feId: `create-${child.id}-${0}`, noData: true, flowElementId: child.id, dataGroupId: groupFeId, order, input: { values: [''], order } as AssignmentInput } as unknown as AssignmentData))
          const newDatas = [parentData, ...childDatas.map((data) => 
            childElementId == data.flowElementId ?
              ({...data,
                input: { values: saveValue, order: groupElementOrder } as AssignmentInput,
                needSave: true,
                isEditable: true,
              }) 
              : data)]
          return update(prevApp, {
            call: {
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: {
                        $push: newDatas,
                      }},
                  },
                  flow: {
                    [form]: {
                      flow: {
                        [groupElementIdx]: {
                          instances: {
                            $set: [{
                              id: 0,
                              flowElementId: groupElement.id,
                              isEditable: true,
                              assignmentId: currentAssignment.id,
                              childInstances: groupElement.flow.map((child, idx: number) =>
                                ({
                                  id: idx,
                                  flowElementId: child.id,
                                  assignmentId: currentAssignment.id,
                                  dataGroupId: groupFeId,
                                  dataGroup: { flowElementId: groupElement.id },
                                  elementType: child.elementType as FORM_ELEMENT_TYPES,
                                  value: childElementId == child.id ? value : '',
                                  noData: true,
                                }),
                              ),
                            }],
                          }}}}}}}},
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const scrollToTop = () => {
    document.body.dispatchEvent(new Event('scrollToTop', {
      bubbles: true,
      cancelable: false,
      composed: false,
    }))
  }

  const submitAssignmentApi = async (
    id: FlowAssignment['id'],
    data: AssignmentData[] = [],
    newGroups: (AssignmentData & { children: AssignmentData[] })[] = [],
  ) => {
    try {
      await submitAssignment({ id, data, newGroups }).unwrap()
      setDraft(false)
      setCallApplication((prevApp) => {
        const { step, assignment } = prevApp.editorSelector
        setDraft(false)
        return update(prevApp, {
          needSave: { $set: false },
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    status: { $set: FLOW_STATUS.SUBMITTED },
                  }}}} },
        })
      })
      /* TODO: Should we also GET application after a time so that automations run? */
      //delayedGetApplication()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Submission successful' } })
    } catch (err) {
      console.log(err)
    }
  }

  const saveAssignmentApi = async (
    id: FlowAssignment['id'],
    allSavedIds: (string | number | undefined)[],
    clickedSave: boolean,
    data: (Omit<AssignmentData, 'flowElement' | 'dataGroup'>)[] = [],
    newGroups: (AssignmentData & { children: AssignmentData[] })[] = [],
    newEditorSelector?: CallApplicationState['editorSelector'],
    scrollId = '',
    scrollTop = false,
  ) => {
    try {
      const apiDatas = data.length || newGroups.length ? await saveAssignment({ id, data, newGroups }).unwrap() : []
      if(clickedSave) dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Changes saved' } })
      if(scrollId) {
        const divElement = document.getElementById(scrollId)
        divElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      if(scrollTop) scrollToTop()
      if(apiDatas.length || newEditorSelector)
        setCallApplication((prevApp) => {
          if(!apiDatas.length) return { ...prevApp, editorSelector: newEditorSelector ?? prevApp.editorSelector}
          const { step, assignment } = prevApp.editorSelector
          const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
          if(!currentAssignment) return prevApp
          const allDatas = [
            ...(currentAssignment?.data || []).filter((data) => !allSavedIds?.includes(data.id)),
            ...apiDatas,
          ] // Filter out the ones that were already saved
          const { allSavedIds: futureSaveIds } = getGroupsToCreate(allDatas)
          const toSaveDatas = allDatas.filter((d) => !!d.needSave && !d.feId && !d.needCreate).map((d) => d.id)
          const needSave: boolean = !!(futureSaveIds.length || toSaveDatas.length)
          return update(prevApp, {
            needSave: { $set: needSave },
            editorSelector: { $set: newEditorSelector ?? prevApp.editorSelector },
            call: { 
              flow: {
                [step]: {
                  assignments: {
                    [assignment]: {
                      data: { $set: allDatas },
                    }}}} },
          })
        })
      return apiDatas || []
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const onSaveHandle = (
    clickedNext?: boolean,
    newForm?: number,
    validate = true,
    submit?: boolean,
  ) => {
    setTimeout(() => {
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        const currentAssignment = prevApp.call?.flow[step].assignments[assignment]
        if(!prevApp.call || !currentAssignment?.data) return prevApp

        const clickedForm = newForm !== undefined
        const clickedSave = !clickedNext && !clickedForm && !submit
        const { callState, anyError, currentFormError } = validateAssignment(
          prevApp.call,
          step,
          form,
          cutoff?.resources || [], // TODO: cutoff could be memoized
        )

        if(submit) {
          if(anyError) {
            if(currentFormError) {
              const divElement = document.getElementById(String(currentFormError))
              divElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Solve all errors before submitting' } })
          } else {
            const createInstances = currentAssignment.data.filter((data) => !!data.needCreate)
            const newGroups = createInstances.map((groupFeData) => ({
              ...groupFeData, id: undefined, feId: undefined, needCreate: undefined,
              children: currentAssignment.data.filter((data) => data.feId &&(data.dataGroupId == groupFeData.feId))
                .map((child) => 
                  ({ ...child, id: undefined, feId: undefined, dataGroupId: undefined, dataGroup: undefined })),
            })) || []
            const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
  
            /* Request to save data to the BE */
            submitAssignmentApi(
              currentAssignment.id,
              saveDatas,
              newGroups,
            )
            return prevApp
          }
        }

        const nextNoValidation = clickedNext && (!anyError || !validate)

        if(!prevApp.needSave && clickedSave) { // Check if changes flag is true, only save in that case
          if(clickedSave) dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Changes saved' } })
          if(nextNoValidation) {
            scrollToTop()
            return { ...prevApp, editorSelector: { ...prevApp.editorSelector, form: form + 1 }}
          }
          return prevApp
        }

        const { newGroups, allSavedIds } = getGroupsToCreate(currentAssignment.data)

        //Only send assignmentDatas with needSave flag
        const saveDatas = currentAssignment.data.filter((data) => !!data.needSave && !data.feId && !data.needCreate)
          .map((data) => ({ ...data, dataGroup: undefined, flowElement: undefined, values: undefined }))
        allSavedIds.push(...saveDatas.map((d) => d.id))
        const newEditorSelector = clickedForm ? { ...prevApp.editorSelector, form: newForm } : undefined
        /* Request to save data to the BE */
        saveAssignmentApi(
          currentAssignment.id,
          allSavedIds,
          clickedSave,
          saveDatas,
          newGroups,
          clickedNext && !currentFormError && !submit ? {...prevApp.editorSelector, form: form + 1} : newEditorSelector,
          currentFormError && clickedNext ? String(currentFormError) : '',
          nextNoValidation || clickedForm,
        )
        return validate || clickedNext ? update(prevApp, { call: { $set: callState } }) : prevApp
      })
    }, 200)
  }

  const handleDeadline = async (assignmentId: number, deadline: string) => {
    try {
      await updateDeadline({ id: assignmentId, deadline }).unwrap()
      setCallApplication((prevApp) => {
        const { step } = prevApp.editorSelector
        const assignmentIdx = (prevApp?.call?.flow[step].assignments || []).findIndex(
          (a) => a.id === assignmentId)
        if(assignmentIdx === -1) throw new Error('Assignment not found')
        return update(prevApp, {
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignmentIdx] : {
                    deadline: { $set: deadline },
                  }}}}},
        })})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Deadline updated' } })
    } catch (err) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Deadline update failed' } })
      console.log(err)
    }
  }

  const handleAssign = async (
    deadline: string | undefined,
    userId: number,
    partitionId: Nullable<string>,
    makeLead = false,
    role: ROLES,
  ) => {
    try {
      const apiAssignment = await assignUser({
        id: Number(callApplication.id),
        flowElementId: flowStep.id,
        deadline,
        userId,
        partitionId,
        makeLead,
        role,
      }).unwrap()
      const newAssignment = { ...apiAssignment, data: [] } as FlowAssignment
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Assignment created' } })
      setCallApplication((prevApp) => update(prevApp, {
        call: {
          flow: {
            [prevApp.editorSelector.step]: {
              assignments: { $push: [newAssignment] },
            }}}}))
    } catch (err) {
      console.log(err)
    }
  }

  const onAssignOther = async (
    deadline: string | undefined,
    userId: number,
    partitionId: Nullable<string>,
    makeLead = false,
    role: ROLES,
  ) => {
    try {
      await acceptAssignment({
        id: assignments[assignment].id,
        accepted: FLOW_STATUS.REFUSED,
        reason: '',
      }).unwrap()
      const newAssignment = await assignUser({
        id: Number(callApplication.id),
        flowElementId: flowStep.id,
        userId,
        partitionId,
        deadline,
        makeLead,
        role,
      }).unwrap()
      setCallApplication((prevApp) => update(prevApp, {
        call: {
          flow: {
            [prevApp.editorSelector.step]: {
              assignments: { $push: [newAssignment] },
            }}}}))
      /* TODO: Prevent doing the Get Application  */
      delayedGetApplication()
      setAssignOtherOpen(false)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User assigned' } })
    } catch (err) {
      console.log(err)
    }

  }

  const handleRevoke = async (assignmentId: number) => {
    try {
      const revokedAssignment = await revoke(assignmentId).unwrap()
      setCallApplication((prevApp) => {
        const { step } = prevApp.editorSelector
        const assignmentIdx = (prevApp?.call?.flow[step].assignments || []).findIndex(
          (a) => a.id === assignmentId)
        if(assignmentIdx === -1 || !prevApp?.call) throw new Error('Assignment not found')
        const newAssignment = { ...prevApp.call.flow[step].assignments[assignmentIdx], ...revokedAssignment }
        return update(prevApp, {
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignmentIdx] : { $set: newAssignment }}}}},
        })})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Assignment updated' } })
    } catch (err) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Assignment revoke failed' } })
      console.log(err)
    }
  }

  const handleDownloadClick = async (uploadId: number, elementId: number) => {
    try {
      const elementName = (flowStep?.flow || [])[form]?.flow.find((el) => el.id === elementId)?.title || ''
      await downloadFile({ uid: callApplication?.uid || '', uploadId, elementName }).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const handleReview = async (assignmentId: number, review: string, deadline?: string, reason?: string) => {
    try {
      const reviewedAssignment = await reviewAssignment({ id: assignmentId, review, deadline, reason }).unwrap()
      setCallApplication((prevApp) => {
        const { step } = prevApp.editorSelector
        const assignmentIdx = (prevApp?.call?.flow[step].assignments || []).findIndex(
          (a) => a.id === assignmentId)
        if(assignmentIdx === -1 || !prevApp?.call) throw new Error('Assignment not found')
        const newAssignment = { ...prevApp.call.flow[step].assignments[assignmentIdx],
          status: reviewedAssignment.status,
          activities: reviewedAssignment.activities,
          deadline: reviewedAssignment.deadline,
        }
        return update(prevApp, {
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignmentIdx] : { $set: newAssignment }}}}},
        })})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Review submitted' } })
    } catch (err) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Review failed' } })
      console.log(err)
    }
  }

  const handleUnlock = async () => {
    try {
      await unlock({ flowElementId: flowStep.id, ids: [Number(callApplication.id)] }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Step unlocked' } })
      delayedGetApplication()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRequestChanges = async (
    assignmentId: number,
    editableElementIds: number[],
    editableNewElementIds: number[],
    deadline?: string,
    reason?: string,
  ) => {
    try {
      const updatedAssignment = await reviewAssignment({
        id: assignmentId,
        review: FLOW_STATUS.CHANGES_REQUESTED,
        editableElementIds,
        editableNewElementIds,
        deadline,
        reason,
      }).unwrap()
      setCallApplication((prevApp) => {
        const { step } = prevApp.editorSelector
        const assignmentIdx = (prevApp?.call?.flow[step].assignments || []).findIndex(
          (a) => a.id === assignmentId)
        if(assignmentIdx === -1 || !prevApp?.call) throw new Error('Assignment not found')
        const newAssignment = { ...prevApp.call.flow[step].assignments[assignmentIdx],
          status: updatedAssignment.status,
          activities: updatedAssignment.activities,
          deadline: updatedAssignment.deadline,
        }
        return update(prevApp, {
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignmentIdx] : { $set: newAssignment }}}}},
        })})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Changes requested' } })
    } catch (err) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Changes request failed' } })
      console.log(err)
    }
  }

  const downloadConsolidated = async (uid: string) => {
    try {
      await downloadPdf(uid).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const delayedGetApplication = () => {
    /* To make sure the BE does the necessary automations */
    dispatch({ type: 'activateLoading' })
    window.setTimeout(async () => {
      try {
        await refetch().unwrap()
      } catch (err) {
        console.log(err)
      }
      dispatch({ type: 'stopLoading' })
    }, 5000)
  }

  const onAccept = async () => {
    try {
      await acceptAssignment({
        id: assignments[assignment].id,
        accepted: FLOW_STATUS.ACCEPTED,
        reason: '',
      }).unwrap()
      delayedGetApplication()
    } catch (err) {
      console.log(err)
    }
  }

  const handleReject = async (reason: string) => {
    try {
      await acceptAssignment({
        id: assignments[assignment].id,
        accepted: FLOW_STATUS.REFUSED,
        reason,
      }).unwrap()
      delayedGetApplication()
      setRejectOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleAssignSubmit = (
    deadline: string | undefined,
    userID: number,
    partitionId: Nullable<string>,
    makeLead: boolean,
    role: ROLES,
  ) => {
    handleAssign(deadline, userID, partitionId, makeLead, role)
    setAssignOpen(false)
  }

  const onInviteUser = (groupInstance: GroupInstance) => {
    const firstNameData = groupInstance.childInstances?.find(
      (child) => (child?.input?.title || '').toLowerCase().includes('first') && (child?.input?.title || '').toLowerCase().includes('name'))
    const lastNameData = groupInstance.childInstances?.find(
      (child) => (child?.input?.title || '').toLowerCase().includes('last') && (child?.input?.title || '').toLowerCase().includes('name'))
    const emailData = groupInstance.childInstances?.find(
      (child) => (child?.input?.title || '').toLowerCase().includes('email') || (child?.input?.title || '').toLowerCase().includes('e-mail'))
    const email = emailData ? String(emailData.input?.values[0]) : ''
    const firstName = firstNameData ?  String(firstNameData.input?.values[0]) : ''
    const lastName = lastNameData ? String(lastNameData.input?.values[0]) : ''
    setInviteOpen({ firstName, lastName, email })
  }

  const handleInvite = async (
    data: {firstName: string; lastName: string; email: string},
    stepId: string,
    role: ROLES,
    deadline?: string,
    makeLead?: boolean,
  ) => {
    try {
      await invite({
        appUid: callApplication?.uid || '',
        flowElementId: Number(stepId),
        assignmentId: currentAssignment?.id || 0,
        deadline: deadline || undefined,
        role,
        ...data,
        makeLead,
      }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: `${data.email} was invited!` } })
      delayedGetApplication()
      setInviteStepOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeUserVisibility = async (userIds: number[]) => {
    try {
      if(!currentAssignment) return
      await changeVisibility({
        id: currentAssignment.id,
        visibility: userIds,
      }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Visibility has changed' } })
      delayedGetApplication()
    } catch (err) {
      console.log(err)
    }
  }

  const setLeadAssignment = async (assignment: FlowAssignment) => {
    try {
      await updateLeadAssignment({
        applicationId: Number(callApplication.id),
        assignmentId: assignment.id,
        flowElementId: flowStep.id,
      }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Assignment updated' } })
      await refetch().unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const handleCOIopen = (coi: boolean, comment: string, elementIdx: number, groupInstanceIdx: number, flowElementId: ApplicationFormElement['id']) => {
    setCOIOpen({coi, comment, elementIdx, groupInstanceIdx, flowElementId})
  }

  const handleCOI = async (
    coi: boolean,
    comment: string,
    element: number,
    flowElementId: ApplicationFormElement['id'],
    groupInstanceIdx: number,
  ) => {
    try {
      const assignmentData = currentAssignment?.data
        .find((data) => (data.flowElementId === flowElementId) && (data.input?.order == groupInstanceIdx))
      if(!assignmentData || !currentAssignment) return
      if(coi !== assignmentData.coi) await updateCoiData(// Only switch coi if user changed
        { id: currentAssignment.id, dataId: assignmentData.id as number }).unwrap()
      await updateCommentData(
        { id: currentAssignment.id, dataId: assignmentData.id as number, comment }).unwrap()
      setCallApplication((prevApp) => {
        const { step, form, assignment } = prevApp.editorSelector
        if(!prevApp.call) return prevApp
        return update(prevApp, {
          call: {
            flow: {
              [step]: {
                assignments: {
                  [assignment]: {
                    data: { // Change the corresponding AssignmentData for the BE
                      $apply: function(datas: AssignmentData[]) {
                        const foundData = datas.findIndex(
                          (data) => (data.flowElementId === flowElementId) &&(data.input?.order == groupInstanceIdx))
                        if(foundData !== -1)
                          datas.splice(foundData, 1, {
                            ...datas[foundData],
                            coi,
                            comment,
                          })
                        return datas
                      }}},
                },
                flow: {
                  [form]: {
                    flow: {
                      [element]: {
                        instances: {
                          [groupInstanceIdx]: {
                            comment: { $set: comment },
                            value: { $set: coi },
                            coi: { $set: coi }}}}}}}}},
          },
        })})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'COI updated' } })
      setCOIOpen(undefined)
    } catch (err) {
      console.log(err)
    }
  }

  if((error as ApiBaseError)?.status == 400 || (error as ApiBaseError)?.status == 500) return <ErrorNotice message='An error occurred while fetching the application' />
  if((error as ApiBaseError)?.status == 404) return <NotFound404 message='The application you’re looking for couldn’t be found' />
  if(!call?.flow || !call?.title) return <Loading loading />

  const { logo = '', title = '', flow, cutoffs = [] } = call
  const lastForm = form === ((flowStep.flow || []).length - 1)

  const { assignable = [] } = permissions
  const isUnlockable = flowStep.config?.isUnlockable
  const hasLead = flowStep.config?.hasLead

  const canUnlockStep = isUnlockable && (!callApplication.unlocked.includes(flowStep.id))
  const currentAssignment = assignments && (assignments.length >= assignment + 1) ? assignments[assignment] : undefined
  const isOwner = (currentAssignment?.ownerId || 0) == user?.id ||
    !!(user?.role && ADMIN_PORTAL_ROLES.includes(user.role) && !currentAssignment?.owner)
  
  let working = false
  let canAcceptAssignment = false
  if(currentAssignment) {
    const { status, deadline } = currentAssignment
    working = canEditAssignment(isOwner, status, deadline, cutoffs)
    canAcceptAssignment = isOwner && (status == FLOW_STATUS.ASSIGNED)
  }

  const collapseFlow = flow.map((flowStep, i: number) => ({
    id: flowStep.id,
    order: flowStep.order,
    title: flowStep.title,
    error: flowStep.error,
    type: flowStep.type,
    hideChilds: isOwner ? false : (!(currentAssignment && currentAssignment.data?.length) && i === step),
    flow: flowStep.flow?.map((flowForm, j: number) => ({
      id: flowForm.id,
      order: flowForm.order,
      title: flowForm.title,
      error: flowForm.error,
      flow: [],
      type: flowForm.type,
      workingStep: (j <= form) || !isOwner,
    })) || [],
    workingStep: i <= workingStep,
  })) as CollapseItem[]

  const onBackHandle = (form: number) => {
    handleSelect(form - 1, ELEMENT_TYPES.FORM)
  }

  const onNextHandle = async (working: boolean, form: number, lastForm: boolean, validate?: boolean) => {
    /* This is to make sure with do not submit data before saving all the changes */
    if(working && !canAcceptAssignment) {
      if(lastForm) {
        //Submit the assignment
        onSaveHandle(true, undefined, true, true)
      } else {
        onSaveHandle(true, undefined, validate)
      }
    } else {
      handleSelect(form + 1, ELEMENT_TYPES.FORM)
    }
  }

  const handleSelect = async (order: number, type: string) => {
    if(loading) return
    if(working && type == 'form' && !canAcceptAssignment) {
      onSaveHandle(false, order, false)
    } else {
      setTimeout(() => {
        changeSelection(order, type)
        scrollToTop()
      }, 200)
    }
  }

  const cutoff = callApplication.cutoffId ?
    cutoffs.find((c) => c.id == callApplication.cutoffId) : activeCutOff(cutoffs)
  
  let allResources: CallPartitionResource[] = []
  cutoffs.forEach((cutOff) => {
    allResources = [...allResources, ...cutOff.resources]
  })

  const currentAndNextCutoffs = applicationCutOff(call.cutoffs, callApplication.cutoffId)
  const nextCutoff = currentAndNextCutoffs.length ? currentAndNextCutoffs[1] : undefined
  const nextAppUID = `${call.uid}${nextCutoff?.order ? String(nextCutoff.order + 1).padStart(2, '0') : ''}-XXX`

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    switch(id) {
      default:
        setCutoffOpen(true)
    }
  }

  const changeNextCutoff = async () => {
    try {
      const newApplication =  await changeToNextCutoff(callApplication.id).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Application updated' } })
      setCutoffOpen(false)
      navigate(Routes.APPLICATION(newApplication.uid))
    } catch (err) {
      console.log(err)
    }
  }

  const assignmentPartition =
    allResources.find((resource) => resource.partition?.id == currentAssignment?.partitionId)?.partition

  const assignmentDefaultDeadline = flowStep?.deadline || 
    stepTimeFrame(call.cutoffs, callApplication.cutoffId, flowStep as FlowStep).timeframe?.end
  //This only exists in Admin Portal:
  //Used to set all assign partition defaults to the one selected by the applicant
  const firstStep = call?.flow[0]
  const firstAssignments = firstStep?.assignments || []
  const firstAssignment = firstAssignments.length ? firstAssignments[0] : undefined
  const firstAssignmentPartitionElement = firstAssignment?.data?.find(
    (data) => (data?.input?.title || '').toLowerCase().includes('partition') && (data?.input?.title || '').toLowerCase().includes('name'))
  const firstAssignmentPartition = allResources.find(
    (partition) => String(partition.id) == firstAssignmentPartitionElement?.input?.values[0])?.partition

  const srEmails = usersSR.map((user) => user.email.toLowerCase()) || []

  if(isUninitialized || isLoading) return <Loading loading />

  return ( // FIXME: DnDWrapper is needed because of CollapseFlow, create 2 CollapseFlows with and without DnDWrapper
    <DnDWrapper>
      <>
        <Banner small isMobile={isMobile}>
          <Grid container alignItems='center'>
            <Grid item>
              <$CallImage src={logo ? `${CDN}/${logo}` : undefined}>
                {logo ? '' : title.substring(0, 2).toLocaleUpperCase()}
              </$CallImage>
            </Grid>
            <Grid item>
              <$PageTitle>
                {title}
              </$PageTitle>
              <Grid container alignItems='center'>
                <$ProposalID>
                  Proposal ID: {draft ? `DRAFT-${callApplication?.id}`: callApplication?.uid}
                </$ProposalID>
                <$EditIcon
                  name='dots-vrtical'
                  color='#fff'
                  clickable
                  noPadding
                  size={20}
                  onClick={(e: React.MouseEvent<any>) => setAnchorEl(e.currentTarget)}
                />
              </Grid>
              <$Chip color={draft ? newTheme.colors.neutral.light : newTheme.colors.success.light}>{draft ? 'Draft' : 'Submitted'}</$Chip>
            </Grid>
          </Grid>
          <$NestedMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            items={menuItems}
            handleItemClick={handleItemClick}
          />
        </Banner>
        <$CallApplication container justifyContent={isMobile ? 'space-between' : 'space-around'}>
          <CallFlowContainer
            isMobile={isMobile}
            flow={collapseFlow}
            workingStep={workingStep}
            step={step}
            form={form}
            handleSelect={handleSelect}
            open={openDrawer}
            setOpen={() => setOpenDrawer((o) => !o)}
            handleReview={handleReview}
            handleRequestChanges={handleRequestChanges}
            handleDeadline={handleDeadline}
            handleOpenAssign={() => setAssignOpen(true)}
            handleOpenInvite={() => setInviteStepOpen(true)}
            handleRevoke={handleRevoke}
            handleDownloadClick={handleDownloadClick}
            proposalId={callApplication?.uid}
            applicationId={callApplication?.id}
            documents={documents || []}
            assignments={assignments}
            selectedAssignment={assignment}
            call={call as FlowCall}
            hasLead={hasLead}
            setLeadAssignment={setLeadAssignment}
            assignmentPartition={assignmentPartition}
            downloadConsolidated={downloadConsolidated}
            onChangeUserVisibility={onChangeUserVisibility}
          />
          {!openDrawer && <$FormContainer item isMobile={isMobile} xs={isMobile ? 11 : 5}>
            {canUnlockStep ?
              <Grid container>
                <Grid item xs={12}>
                  <ConfirmAction disabled={isUnlocking} onConfirm={handleUnlock} title='Unlock Step'
                    description='When this step is unlocked all users with assignments will have access to it.'>
                    <NewButton loading={isUnlocking} >Unlock Step</NewButton>
                  </ConfirmAction>
                </Grid>
              </Grid>
              : <ApplicationFlowStep
                call={call}
                step={step}
                form={form}
                user={user as User}
                lastForm={lastForm}
                assignment={assignment}
                options_groups={options_groups}
                onChangeValue={onChangeValue}
                onChangeFile={onChangeFile}
                openCOI={handleCOIopen}
                removeInstance={removeGroupInstance}
                addInstance={addGroupInstance}
                onBackHandle={onBackHandle}
                onNextHandle={onNextHandle}
                onSaveHandle={() => onSaveHandle(false, undefined, false)}
                onChangeGroupElementValue={onChangeGroupElementValue}
                onAccept={onAccept}
                onReject={() => setRejectOpen(true)}
                onAssignOther={() => setAssignOtherOpen(true)}
                handleOpenAssign={() => setAssignOpen(true)}
                handleOpenInvite={() => setInviteStepOpen(true)}
                onDownloadClick={handleDownloadClick}
                canAssign={true}
                loading={loading}
                activeCutOff={cutoff}
                allResources={allResources}
                onInviteUser={onInviteUser}
                documents={documents || []}
                srEmails={srEmails}
              />
            }
          </$FormContainer>}
          <AssignmentFlowContainer
            isMobile={isMobile}
            handleSelect={handleSelect}
            handleReview={handleReview}
            handleRequestChanges={handleRequestChanges}
            handleDeadline={handleDeadline}
            handleOpenAssign={() => setAssignOpen(true)}
            handleOpenInvite={() => setInviteStepOpen(true)}
            handleRevoke={handleRevoke}
            handleDownloadClick={handleDownloadClick}
            proposalId={callApplication?.uid}
            applicationId={callApplication?.id}
            documents={documents || []}
            assignments={assignments}
            selectedAssignment={assignment}
            step={step}
            call={call as FlowCall}
            hasLead={hasLead}
            setLeadAssignment={setLeadAssignment}
            assignmentPartition={assignmentPartition}
            downloadConsolidated={downloadConsolidated}
            onChangeUserVisibility={onChangeUserVisibility}
          />
        </$CallApplication>
        {rejectOpen &&
          <NewModal open alternateTitle title='Reject Assignment' onClose={() => setRejectOpen(false)}>
            <RejectModal
              loading={isAccepting}
              handleReject={handleReject}
              handleCancel={() => setRejectOpen(false)}
            />
          </NewModal>
        }
        {assignOtherOpen &&
      <NewModal open alternateTitle title='Assign User' onClose={() => setAssignOtherOpen(false)}>
        <AssignModal
          loading={isAccepting || isAssigning}
          assignable={assignable}
          applicationId={callApplication?.id}
          handleAssign={onAssignOther}
          handleCancel={() => setAssignOtherOpen(false)}
          activeCutOff={cutoff}
          initialPartition={firstAssignmentPartition ? firstAssignmentPartition : assignmentPartition}
          initialDeadline={assignmentDefaultDeadline}
          canHaveLead={flowStep.config?.hasLead}
        />
      </NewModal>
        }
        {assignOpen &&
    <NewModal open alternateTitle title='Assign User' onClose={() => setAssignOpen(false)}>
      <AssignModal
        loading={isAccepting || isAssigning}
        assignable={assignable}
        applicationId={callApplication?.id}
        handleAssign={handleAssignSubmit}
        handleCancel={() => setAssignOpen(false)}
        activeCutOff={cutoff}
        initialPartition={firstAssignmentPartition ? firstAssignmentPartition : assignmentPartition}
        initialDeadline={assignmentDefaultDeadline}
        canHaveLead={flowStep.config?.hasLead}
      />
    </NewModal>
        }
        {!!inviteOpen &&
      <NewModal open alternateTitle title='Invite User' onClose={() => setInviteOpen(undefined)}>
        <InviteModal
          loading={isInviting}
          assignable={assignable}
          data={inviteOpen!}
          cutoffId={callApplication.cutoffId}
          applicationUid={callApplication.uid}
          cutoffs={call.cutoffs || []}
          steps={call?.flow || []}
          handleInvite={handleInvite}
          handleCancel={() => setInviteOpen(undefined)}
          initialDeadline={assignmentDefaultDeadline}
          canHaveLead={flowStep.config?.hasLead}
        />
      </NewModal>
        }
        {inviteStepOpen &&
      <NewModal open alternateTitle title='Invite User' onClose={() => setInviteStepOpen(false)}>
        <InviteModal
          loading={isInviting}
          assignable={assignable}
          data={inviteOpen!}
          cutoffId={callApplication.cutoffId}
          applicationUid={callApplication.uid}
          cutoffs={call.cutoffs || []}
          steps={[flowStep]}
          handleInvite={handleInvite}
          handleCancel={() => setInviteStepOpen(false)}
          initialDeadline={assignmentDefaultDeadline}
          canHaveLead={flowStep.config?.hasLead}
        />
      </NewModal>
        }
        {!!COIOpen &&
          <NewModal open alternateTitle title={`Conflict of interest SR #${COIOpen.groupInstanceIdx + 1}`} onClose={() => setCOIOpen(undefined)}>
            <COIModal
              loading={updatingCoi || updatingComment}
              coi={!!COIOpen.coi}
              comment={COIOpen.comment}
              handleCOI={(
                coi: boolean,
                comment: string,
              ) => handleCOI(coi, comment, COIOpen.elementIdx, COIOpen.flowElementId, COIOpen.groupInstanceIdx)}
              handleCancel={() => setCOIOpen(undefined)}
            />
          </NewModal>
        }
        {cutoffOpen &&
          <ConfirmModal
            open
            title='Change to next Cutoff'
            description={nextCutoff ?
              <>
                {'This action will update the cutoff of all assignments and change this application id from '}
                <b>{callApplication.uid}</b> to <b>{nextAppUID}</b>
              </>
              : 'A future cutoff must be added before continuing.'
            }
            disabled={!nextCutoff || changingCutoff}
            onConfirm={changeNextCutoff}
            onClose={() => setCutoffOpen(false)}
          />
        }
      </>
    </DnDWrapper>
  )
}
