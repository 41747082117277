import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Title, NewButton, Notice, FLOW_STATUS, ROLES, Input, newTheme, SvgIcon, replaceURLs, ConfirmAction, NotFound404, stepTimeFrame, ADMIN_ID, ADMIN_PORTAL_ROLES, BrowserLocaleDateFormatter } from '../';
import { ApplicationFlowForm } from './ApplicationFlowForm';
import { $Buttons, $ButtonsStart, $ButtonsEnd, $WarningContainer, $WarningGrid, $Warning, $Description, $BottomActions, $StepContent } from './styles';
export const ApplicationFlowStep = ({ call, step, form, user, profile, assignment, onChangeValue, onChangeFile, openCOI = () => null, onChangeGroupElementValue, options_groups, removeInstance, addInstance, onBackHandle, onNextHandle, onSaveHandle, lastForm, onAccept, onAssignOther, handleOpenAssign, handleOpenInvite, onReject, activeCutOff, onDownloadClick, canAssign, loading, disableSave, allResources, firstAssignmentDraft, onInviteUser, documents, stepLocked, srEmails = [], }) => {
    if (!!call && !call?.flow.length)
        return React.createElement(NotFound404, { message: 'This application is not available' });
    const currentStep = call.flow[step];
    const { assignments = [], config } = currentStep;
    if (!currentStep?.flow || !currentStep?.flow.length || (currentStep?.flow.length < form))
        return null;
    const currentForm = currentStep.flow[form];
    const currentAssignment = assignments[assignment] || {};
    const { title = '', description = '', flow } = currentForm;
    const { ownerId = 0, owner, status = FLOW_STATUS.DRAFT, data = [], deadline = '', submittedAt } = currentAssignment;
    const isAdmin = ADMIN_PORTAL_ROLES.includes(user?.role || ROLES.USER);
    const isOwner = ownerId ? ownerId == user?.id : (isAdmin && (!owner || ownerId == ADMIN_ID));
    const rejectedAssignment = status == FLOW_STATUS.REJECTED;
    const refusedAssignment = status == FLOW_STATUS.REFUSED;
    const revokedAssignment = status == FLOW_STATUS.REVOKED;
    const submittedAssignment = status == FLOW_STATUS.SUBMITTED || status == FLOW_STATUS.RESUBMITTED;
    const disableForms = (status == FLOW_STATUS.APPROVED) || rejectedAssignment || refusedAssignment ||
        revokedAssignment || submittedAssignment;
    const changesRequested = !!submittedAt && (status == FLOW_STATUS.CHANGES_REQUESTED);
    const { timeframe, validTimeframe } = stepTimeFrame(call.cutoffs, activeCutOff?.id, currentStep);
    const today = new Date();
    const timeFrameStarted = timeframe ? today.getTime() >= new Date(timeframe.start).getTime() : true;
    const futureTimeframeStart = !timeFrameStarted && (isOwner && (changesRequested || firstAssignmentDraft));
    const deadlineDate = deadline ? new Date(deadline) : null;
    const validDeadline = (!deadline || !!(deadlineDate && deadlineDate.getTime() >= today.getTime()));
    const canEditAssignment = isOwner && ((changesRequested && validDeadline) ||
        (!disableForms && validDeadline)) && (!firstAssignmentDraft || (firstAssignmentDraft && !!activeCutOff && validTimeframe));
    const canAcceptAssignment = isOwner && (status == FLOW_STATUS.ASSIGNED);
    const deadlinePassed = !validDeadline && !disableForms;
    const working = canEditAssignment && !canAcceptAssignment;
    const showCOI = isAdmin;
    const hasData = !!data.length;
    const canSeeAssignmentData = hasData || isOwner || (isAdmin && status == FLOW_STATUS.ACCEPTED);
    const assignmentExists = !!assignments.length;
    const message = (status, showData) => {
        switch (status) {
            case FLOW_STATUS.REFUSED:
                return '<b>User has refused this assignment.</b>';
            case FLOW_STATUS.REVOKED:
                return '<b>This assignment was revoked.</b>';
            case FLOW_STATUS.CONFLICTED:
                return '<b>This assignment has conflicts.</b>';
            case FLOW_STATUS.DRAFT:
            case FLOW_STATUS.ACCEPTED:
                return showData ? replaceURLs(description) : 'The user has not filled anything yet.';
            default:
                return replaceURLs(description);
        }
    };
    const descriptionText = canAcceptAssignment
        ? 'You\'ve been appointed to contribute to this application. Please accept or reject this assignment.'
        : (message(status, canSeeAssignmentData) || '');
    const disableNextSubmit = loading || (!step && lastForm && !call.published);
    if (stepLocked)
        return React.createElement(Notice, { message: 'Actions will be available soon' });
    if (firstAssignmentDraft && (step > 0))
        return React.createElement(Notice, { message: 'Information will be available soon' });
    return (React.createElement(React.Fragment, null,
        React.createElement($StepContent, { key: currentAssignment.id, container: true, spacing: 2 },
            (deadlinePassed || futureTimeframeStart) &&
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true },
                        React.createElement($WarningContainer, { item: true },
                            React.createElement($WarningGrid, { container: true },
                                React.createElement(SvgIcon, { name: 'warning', color: newTheme.colors.feedback.error, size: 16 }),
                                React.createElement($Warning, null, deadlinePassed ?
                                    changesRequested ? `The deadline to update this assignment was ${deadlineDate ? BrowserLocaleDateFormatter.format(deadlineDate) : '-'}`
                                        : `The deadline for this assignment was ${deadlineDate ? BrowserLocaleDateFormatter.format(deadlineDate) : '-'}`
                                    : `Submissions will be available starting at ${timeframe?.start ? BrowserLocaleDateFormatter.format(new Date(timeframe.start)) : '-'}`))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Title, { noMargin: true }, canEditAssignment ? title : currentStep.title)),
            (!assignmentExists || descriptionText) &&
                React.createElement(Grid, { item: true, xs: 12 }, !assignmentExists ?
                    (!canAssign && React.createElement(Notice, { message: 'Actions will be available soon' }))
                    : React.createElement($Description, { dangerouslySetInnerHTML: { __html: descriptionText } })),
            refusedAssignment && React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Input, { disabled: true, value: currentAssignment.activities.find((act) => act.status == FLOW_STATUS.REFUSED)?.reason || '', title: 'Reasons user gave for refusal', name: 'reason', multiline: true, minRows: 5, type: 'text', hideItalic: true })),
            assignmentExists && canSeeAssignmentData && !canAcceptAssignment && !refusedAssignment && !revokedAssignment &&
                React.createElement(ApplicationFlowForm, { call: call, profile: profile, formElements: flow, stepId: currentStep.id, assignment: currentAssignment, canEditAssignment: canEditAssignment, options_groups: options_groups, onChangeValue: onChangeValue, onChangeFile: onChangeFile, onChangeGroupElementValue: onChangeGroupElementValue, removeInstance: removeInstance, addInstance: addInstance, revokedAssignment: revokedAssignment, showCOI: showCOI, onDownloadClick: onDownloadClick, activeCutOff: activeCutOff, allResources: allResources, onInviteUser: onInviteUser, openCOI: openCOI, documents: documents, changesRequested: changesRequested, srEmails: srEmails, loading: loading })),
        assignmentExists && !refusedAssignment && React.createElement(React.Fragment, null, canAcceptAssignment ?
            React.createElement($Buttons, { container: true, justifyContent: 'space-between' },
                React.createElement($ButtonsStart, null,
                    React.createElement(NewButton, { onClick: onAccept }, "Accept"),
                    canAssign && React.createElement(NewButton, { variant: 'outlined', onClick: onAssignOther }, "Assign Another User")),
                React.createElement(NewButton, { error: true, onClick: onReject }, "Reject"))
            :
                canSeeAssignmentData && React.createElement($BottomActions, { container: true, spacing: 2, justifyContent: 'space-between' },
                    form && !revokedAssignment ? React.createElement(NewButton, { variant: 'outlined', disabled: loading, onClick: () => onBackHandle(form) }, "Back") : React.createElement("div", null),
                    React.createElement($ButtonsEnd, null,
                        working && React.createElement(NewButton, { variant: 'outlined', disabled: loading || disableSave, onClick: () => onSaveHandle() }, "Save Changes"),
                        (revokedAssignment || (!working && lastForm)) ? null :
                            ((!step || config?.submitConfirmation) && lastForm && !loading && !disableNextSubmit ?
                                React.createElement(ConfirmAction, { disabled: loading, title: !step ? 'Submit the proposal' : 'Assignment submission', question: !step ? 'Are you sure you want to submit this proposal?' : 'Are you sure you want to submit this assignment?', onConfirm: () => onNextHandle(working, form, lastForm) },
                                    React.createElement(NewButton, { loading: loading, onClick: () => onNextHandle(working, form, lastForm) }, "Submit"))
                                : React.createElement(NewButton, { disabled: disableNextSubmit, loading: loading, onClick: () => onNextHandle(working, form, lastForm, !futureTimeframeStart) }, lastForm ? 'Submit' : 'Next'))))),
        !hasData && canAssign && !canAcceptAssignment && !canEditAssignment ?
            React.createElement($Buttons, { container: true, justifyContent: 'space-between' },
                React.createElement($ButtonsStart, null,
                    React.createElement(NewButton, { variant: 'outlined', onClick: handleOpenAssign }, "Assign user"),
                    isAdmin && React.createElement(NewButton, { variant: 'outlined', onClick: handleOpenInvite }, "Invite user")))
            : null));
};
